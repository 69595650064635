import { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Input from '@/components/ui/input'
import Button from '@/components/ui/button'
import Alert from '@/components/ui/alert'

import api from '@/services/api'
import useUser from '@/hooks/user'
import useTranslation from '@/hooks/translation'
import useAnalytics from '@/hooks/analytics'

function LoginComponent({ hasBorder = true, onSuccess }) {
  const [alert, setAlert] = useState(null)
  const { mutateUser } = useUser()

  const { track } = useAnalytics()
  const { t } = useTranslation()

  const { handleSubmit, handleChange, values, errors, touched, isSubmitting } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      onSubmit: (values) => {
        setAlert(null)
        return api.auth
          .signIn({ email: values.email, password: values.password })
          .then(async ({ status }) => {
            if (status === 'ok') {
              const { data } = await api.auth.me()
              mutateUser(data)
              track('account_login', { login_method: 'email' })

              if (onSuccess) {
                onSuccess()
              }
            }
          })
          .catch((e) => {
            if (e.status === 401) {
              setAlert({
                type: 'error',
                messages: [t('components.login.error.invalidCredentials')],
              })
            }
          })
      },
      validationSchema: yup.object().shape({
        email: yup
          .string()
          .trim()
          .email(t('components.login.email.invalid'))
          .required(t('defaults.forms.required')),
        password: yup.string().required(t('defaults.forms.required')),
      }),
    })

  return (
    <>
      <div className="mb-10">
        <Input
          id="email"
          type="email"
          label={t('components.login.email.label')}
          error={touched.email && errors.email}
          value={values.email}
          onChange={handleChange}
          noBorder={!hasBorder}
          required
        />
      </div>
      <div className="mb-10">
        <Input
          id="password"
          type="password"
          label={t('components.login.password.label')}
          error={touched.password && errors.password}
          value={values.password}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }}
          noBorder={!hasBorder}
          required
        />
      </div>
      <div className="mb-10 flex justify-center">
        <Button
          plain
          size="sm"
          href={`/auth/lost-password${
            values.email && !errors.email ? '?email=' + values.email : ''
          }`}
          trackingEventName="website_lost_password_clicked"
        >
          {t('components.login.forgotPassword')}
        </Button>
      </div>
      <Button
        type="submit"
        size="xl"
        onClick={handleSubmit}
        loading={isSubmitting}
        className="w-full mt-15"
      >
        {t('components.login.button')}
      </Button>
      {alert ? (
        <Alert messages={alert.messages} type={alert.type} className="mt-15" />
      ) : null}
    </>
  )
}

export default LoginComponent
