import FooterComponent from '@/components/Footer'
import Button from '@/components/ui/button'
import clsx from 'clsx'
import styles from './error.module.css'

function ErrorLayout({
  title,
  subtitle,
  message,
  buttonText,
  buttonHref,
  buttonOnClick,
}) {
  return (
    <>
      <div
        className={clsx(
          'bg-bg min-h-app relative z-px flex flex-col pt-20 md:pt-20',
          styles.background
        )}
      >
        <div className="container col-12 text-center flex justify-start space-y-20 md:space-y-40 flex-1 flex-col">
          <p className="text-20 sm:text-30 font-bold">{subtitle}</p>
          <h1 className="text-100 sm:text-150 md:text-260 font-bold leading-none py-10">
            {title}
          </h1>
          <p className="sm:text-18">{message}</p>
          <div className="mx-auto inline-block">
            <Button size="xl" href={buttonHref} onClick={buttonOnClick}>
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  )
}

export default ErrorLayout
