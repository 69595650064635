import { useState, useEffect } from 'react'

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'WindowsPhone', or 'unknown'.
 * @returns {String}
 */
function getMobileOperatingSystem() {
  if (typeof window === 'undefined') {
    return 'unknown;'
  }

  var userAgent =
    window.navigator.userAgent || window.navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'WindowsPhone'
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return 'unknown'
}

const useMobileOperator = () => {
  const [os, setOs] = useState('unknown')

  useEffect(() => {
    setOs(getMobileOperatingSystem())
  }, [])

  return os
}

export default useMobileOperator
