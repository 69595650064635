/* eslint-disable @typescript-eslint/no-explicit-any */
import { reportError } from '../../utils/error'

const doNotReportThese = [401, 404, 410]

/**
 * Options given to execute the query
 */
export type Options = {
  api?: string
  body?: object
  method?: 'GET' | 'POST' | 'PUT' | 'DEL'
  headers?: Record<string, string>
}

export type ErrorResult = {
  status: number
  statusText: string
  content: any | null
  data?: any // compatibility
}

/**
 * The default fetcher with any kind of result
 */
export type Fetcher<R> = (
  endpoint: string,
  opts?: Options
) => Promise<ErrorResult | R>

/**
 * An Homer response
 */
export type HomerResponse<R> = {
  data?: R
}

/**
 * A standard Homer Fetcher that returns an homer response
 */
export type HomerFetcher<R> = Fetcher<HomerResponse<R>>

/**
 * The default fetcher
 * @param endpoint the given end options
 * @param opts options
 * @returns an error result or a parsed json
 */
export async function fetcher<R>(
  endpoint: string,
  opts: Options = {}
): Promise<ErrorResult | R> {
  const apiUrl = opts.api || '/api'
  const body = JSON.stringify(opts.body || undefined)
  const method = opts.method || 'GET'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...opts.headers,
  }

  return fetch(`${apiUrl}${endpoint}`, {
    method: method || 'GET',
    body,
    headers,
  }).then(async (R) => {
    if (R.ok === false) {
      const result: ErrorResult = {
        status: R.status,
        statusText: R.statusText,
        content: null,
      }

      try {
        result.content = await R.json()
        // for compatbilitity reasons, if content contains a data, we expose it
        if (result.content?.['data']) {
          result.data = result.content['data']
        }
      } catch (e) {
        // not a json field
        result.content = null
      }

      // Don't report 401s, 404s, etc. since that's the expected behavior
      if (!doNotReportThese.includes(R?.status)) {
        reportError(
          new Error(`Failed to fetch ${method} ${apiUrl}${endpoint}`),
          {
            extra: {
              status: R.status,
              statusText: R.statusText,
              ok: R.ok,
              url: R.url,
              response: result.content || R,
            },
          }
        )
      }

      throw result
    }

    try {
      const r = await R.json()
      return r
    } catch (e) {
      reportError(new Error(`Failed to parse ${apiUrl}${endpoint} response`), {
        extra: {
          status: R.status,
          statusText: R.statusText,
          ok: R.ok,
          url: R.url,
          response: R,
          error: e,
        },
      })
      throw e
    }
  })
}

export function isError<R>(response: ErrorResult | R) {
  return (
    (response as any)?.['status'] !== undefined &&
    (response as any)?.['statusText'] !== undefined
  )
}
