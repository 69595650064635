import { useRef, forwardRef } from 'react'
import { useFocusRing } from '@react-aria/focus'
import { useCheckbox } from '@react-aria/checkbox'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import { useToggleState } from '@react-stately/toggle'
import { useId } from '@react-aria/utils'
import clsx from 'clsx'

const Checkbox = ({
  error,
  label,
  isValid,
  forwardedRef,
  required,
  disabled,
  labelClassName = '-mt-1',
  ringClassName = '',
  className = 'flex items-start cursor-pointer gap-15 text-dark',
  isRounded = false,
  checkedColor = 'dark',
  ...props
}) => {
  const id = useId(props.id)
  const errorId = useId(`error-${id}`)

  const ref = useRef()
  const inputRef = forwardedRef || ref

  const state = useToggleState({
    ...props,
    id,
    children: label,
    isSelected: props.value,
  })
  const { inputProps } = useCheckbox(
    { ...props, id, children: label },
    state,
    inputRef
  )
  const { isFocusVisible, focusProps } = useFocusRing()

  return (
    <div>
      <label
        htmlFor={id}
        className={clsx(
          disabled ? 'opacity-50' : '',
          state.isSelected ? 'selected opacity-100' : 'opacity-70',
          className
        )}
      >
        <VisuallyHidden>
          <input
            {...inputProps}
            {...focusProps}
            ref={inputRef}
            aria-invalid={error ? true : undefined}
            aria-describedby={error ? errorId : undefined}
            required={required}
            disabled={disabled}
          />
        </VisuallyHidden>
        {/*className={clsx(
            `flex-shrink-0 w-25 h-25 md:w-20 md:h-20 p-2`,
            isRounded ? 'rounded-full' : 'rounded-5',
            isFocusVisible
              ? 'ring-2 ring-primary'
              : error
              ? 'ring-2 ring-danger'
              : isValid
              ? 'ring-2 ring-success'
              : ringClassName
          )*/}
        <div
          className={clsx(
            isRounded ? 'rounded-full' : 'rounded-5',
            'border-2 w-25 h-25 md:w-20 md:h-20 shrink-0',
            'flex items-center justify-center',
            // ring & border color
            isFocusVisible || error || isValid
              ? 'ring border-white'
              : state.isSelected
              ? `border-${checkedColor}`
              : 'border-dark-40',
            // ring color
            isFocusVisible
              ? 'ring-primary'
              : error
              ? 'ring-danger'
              : isValid
              ? 'ring-success'
              : ringClassName,
            // bg-color
            state.isSelected ? `bg-${checkedColor}` : 'bg-white'
          )}
          aria-hidden="true"
        >
          {state.isSelected ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="10"
              viewBox="0 0 12 10"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3569 1.59838L4.71401 9.17754L0.642578 5.5954L1.4277 4.7085L4.60686 7.49778L10.4789 0.833496L11.3569 1.59838Z"
                fill="white"
              />
            </svg>
          ) : null}
        </div>
        <span className={labelClassName}>{label}</span>
      </label>
      {error ? (
        <p className="text-danger text-14 mt-5" id={errorId}>
          {error}
        </p>
      ) : null}
    </div>
  )
}

export default forwardRef(function WrappedCheckbox(props, ref) {
  return <Checkbox {...props} forwardedRef={ref} />
})
