import { createContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

// Hooks
import usePrevious from '@/hooks/previous'

// Utils
import { getCookie, setCookie, setPartnershipsCookie } from '@/utils/cookies'
import { bootstrapRudderStack } from '@/utils/rudderstack'
// use only plausible to track cookie consent
import { safeRawTrack } from '@/providers/analytics'

const DEACTIVATE_FOR_PARTNERS = ['petitfute']
export const CookieContext = createContext()

export function CookieProvider({ children }) {
  const router = useRouter()
  const { query } = router
  const [isCookieBannerVisible, setIsCookieBannerVisible] = useState()
  const [cookieConsent, setCookieConsent] = useState(
    getCookie('ulysse:cookie-consent')
  )

  const acceptCookie = () => {
    safeRawTrack('cookie_consent_accepted')
    setCookie('ulysse:cookie-consent', true, 365)
    setCookieConsent(getCookie('ulysse:cookie-consent'))
    setIsCookieBannerVisible(false)
  }

  const refuseCookie = () => {
    safeRawTrack('cookie_consent_refused')
    setCookie('ulysse:cookie-consent', false, 365)
    setCookieConsent(getCookie('ulysse:cookie-consent'))
    setIsCookieBannerVisible(false)
  }

  const showCookieBanner = () => setIsCookieBannerVisible(true)

  // Show cookie banner if cookie is null
  // And force cookie consent if needed
  useEffect(() => {
    if (cookieConsent === null && router.pathname !== '/') {
      if (DEACTIVATE_FOR_PARTNERS.includes(query.partnerId)) {
        setCookieConsent('true')
      } else {
        setIsCookieBannerVisible(true)
      }
    }
  }, [query, cookieConsent])

  // Catch partnerId in query params to store it in cookie and use it later when ordering
  useEffect(() => {
    if (query.partnerId) {
      setPartnershipsCookie(query.partnerId)
    }
  }, [query])

  const prevCookieConsent = usePrevious(cookieConsent)

  useEffect(() => {
    if (cookieConsent === 'true') {
      bootstrapRudderStack()
    }

    // if user has previously selected true, and finally decided he does not want to be tracked anymore,
    // then we fully reload browser in order to not load any tracking scripts
    if (prevCookieConsent === 'true' && cookieConsent === 'false') {
      router.reload(window.location.pathname)
    }
  }, [cookieConsent])

  return (
    <CookieContext.Provider
      value={{
        isCookieBannerVisible,
        showCookieBanner,
        acceptCookie,
        refuseCookie,
      }}
    >
      {children}
    </CookieContext.Provider>
  )
}
