import { reportError } from '@/utils/error'

/**
 * Fetch the last news from our local feed, which is a cache
 * @returns the news
 */
async function fetchLastNews(endpoint) {
  console.time('fetchLastNews')
  try {
    const resp = await fetch(endpoint + '/api/feed')
    const body = await resp.json()
    console.timeEnd('fetchLastNews')
    return body
  } catch (error) {
    console.timeEnd('fetchLastNews')
    console.error(error)
    reportError(error)
    return []
  }
}

/**
 * Fetch the last news
 */
export async function getLastNews({ req, locale }) {
  if (!req || locale !== 'fr' || process.env['FETCH_LAST_NEWS'] !== 'yes') {
    return []
  }

  const proto =
    req.headers['x-forwarded-proto'] ??
    (req.connection?.encrypted ? 'https' : 'http')

  return await fetchLastNews(proto + '://' + req.headers.host)
}

/**
 * Read the news feed and returns the props
 * @returns the static props
 */
export async function getLastNewsProps(ctx) {
  const lastNews = await getLastNews(ctx)
  return {
    props: {
      lastNews,
    },
    revalidate: 60,
  }
}
