import { useState } from 'react'
import Cross from '@/assets/svg/cross.svg'
import Button from '@/components/ui/button'
import useTranslation from '@/hooks/translation'

export default function Banner({ children }) {
  const { t } = useTranslation()
  const [show, setShow] = useState(true)

  return show ? (
    <section className="flex justify-between gap-10 bg-warning-light md:bg-warning-dark text-dark md:text-white text-13 py-10 px-15">
      <div className="flex-grow text-center">{children}</div>
      {false && (
        <Button
          unstyled
          aria-label={t('components.banner.closeButtonAriaLabel')}
          onClick={() => setShow(false)}
        >
          <Cross className="w-15 " />
        </Button>
      )}
    </section>
  ) : null
}
