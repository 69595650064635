'use client'

import useSWRImmutable from 'swr/immutable'
import { fetcher } from '@/services/api/core'
import TrustpilotLogo from '@/assets/svg/trustpilot.svg'
import Star from '@/assets/svg/trustpilot-star.svg'

import useTranslation from '@/hooks/translation'

function TrustpilotScore({
  className,
  size,
  layout = 'inline',
  hideReviews = false,
}) {
  const { data: trustpilot } = useSWRImmutable('/api/trustpilot', (url) =>
    fetcher(url, {
      api: 'https://ulysse-tooling-api.herokuapp.com',
    })
  )

  const { t } = useTranslation()
  const logoSize =
    size === 'lg' ? { width: 118, height: 24 } : { width: 84, height: 17 }

  if (layout === 'block') {
    return (
      <a
        href={t('urls.trustpilot.reviews')}
        target="_blank"
        rel="noreferrer noopener"
        aria-label={
          trustpilot
            ? `Trustpilot: ${trustpilot.score}/5 - ${t(
                'components.trustpilotScore.reviews',
                {
                  count: trustpilot.count,
                }
              )}`
            : 'Trustpilot'
        }
        className={`inline-block ${className}`}
      >
        <TrustpilotLogo {...logoSize} className="mr-10" />
        {trustpilot && (
          <>
            <div className="flex items-center my-10">
              {[...Array(Math.round(trustpilot.score))].map((a, b) => (
                <div
                  key={b}
                  className="h-30 w-30 bg-trustpilot text-white flex items-center justify-center mr-2"
                >
                  <Star />
                </div>
              ))}
            </div>
            <p
              className={`${
                size === 'lg' ? 'text-18' : 'text-14'
              } leading-none`}
            >
              {trustpilot.score}/5
              {!hideReviews && (
                <>
                  {' '}
                  -{' '}
                  {t('components.trustpilotScore.reviews', {
                    count: trustpilot.count,
                  })}
                </>
              )}
            </p>
          </>
        )}
      </a>
    )
  }

  if (layout === 'inline') {
    return (
      <div className={`flex items-center ${className}`}>
        {trustpilot && (
          <a
            href={t('urls.trustpilot.reviews')}
            target="_blank"
            rel="noreferrer noopener"
            aria-label={
              trustpilot
                ? `Trustpilot: ${trustpilot.score}/5 - ${t(
                    'components.trustpilotScore.reviews',
                    {
                      count: trustpilot.count,
                    }
                  )}`
                : 'Trustpilot'
            }
          >
            <TrustpilotLogo {...logoSize} className="mr-10" />
          </a>
        )}
        {trustpilot && (
          <p
            className={`leading-none ${size === 'lg' ? 'text-18' : 'text-14'}`}
          >
            {trustpilot.score}/5
            {!hideReviews && (
              <>
                {' '}
                -{' '}
                {t('components.trustpilotScore.reviews', {
                  count: trustpilot.count,
                })}
              </>
            )}
          </p>
        )}
      </div>
    )
  }

  return null
}

export default TrustpilotScore
