import { useContext, useEffect } from 'react'
import { ScrollContext } from '@/providers/scroll'

const useScroll = ({ isDisabled }) => {
  const { preventScroll, allowScroll } = useContext(ScrollContext)

  useEffect(() => {
    if (!isDisabled) {
      preventScroll()
    } else {
      allowScroll()
    }
  }, [isDisabled])
}

export default useScroll
