import { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { formatISO } from 'date-fns'

import Input from '@/components/ui/input'
import Button from '@/components/ui/button'
import Alert from '@/components/ui/alert'

import api from '@/services/api'

import useTranslation from '@/hooks/translation'
import useAnalytics from '@/hooks/analytics'

import useUser from '@/hooks/user'

function RegisterComponent({ hasBorder = true, onSuccess }) {
  const [alert, setAlert] = useState(null)
  const { track, identify } = useAnalytics()
  const { mutateUser } = useUser()
  const { t } = useTranslation()

  const { handleSubmit, handleChange, values, errors, touched, isSubmitting } =
    useFormik({
      initialValues: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
      },
      onSubmit: (values) => {
        setAlert(null)
        return api.auth
          .signUp({
            email: values.email,
            password: values.password,
            first_name: values.firstname,
            last_name: values.lastname,
          })
          .then(async (data) => {
            const { status, errors } = data
            if (status === 'ok') {
              track('account_created', { account_creattion_method: 'email' })
              identify(
                {
                  account_created_at: formatISO(new Date()),
                },
                values.email
              )
              setAlert({
                type: 'success',
                messages: [t('components.register.success')],
              })

              if (data.data) {
                // registration with auto login
                // log the user in
                const { data } = await api.auth.me()
                mutateUser(data)
                track('account_login', { login_method: 'email' })

                // we call the onsuccess only on the new version
                if (onSuccess) {
                  onSuccess()
                }
              }
            }
            if (errors) {
              let err = Object.keys(errors)
              setAlert({
                type: 'error',
                messages: err.map((E) =>
                  E === 'email'
                    ? t('components.register.invalidEmail')
                    : E === 'password'
                    ? t('components.register.invalidPassword')
                    : null
                ),
              })
            }
          })
          .catch((err) => {
            console.error(err)
            setAlert({
              type: 'error',
              messages: [t('defaults.forms.error')],
            })
          })
      },
      validationSchema: yup.object().shape({
        firstname: yup
          .string()
          .trim()
          .required(t('defaults.forms.required'))
          .min(2, t('components.register.firstname.min')),
        lastname: yup
          .string()
          .trim()
          .required(t('defaults.forms.required'))
          .min(2, t('components.register.lastname.min')),
        email: yup
          .string()
          .trim()
          .email(t('components.register.email.invalid'))
          .required(t('defaults.forms.required')),
        password: yup
          .string()
          .required(t('defaults.forms.required'))
          .min(8, t('components.register.password.min')),
      }),
    })

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="md:col-6 mb-10">
          <Input
            id="firstname"
            label={t('components.register.firstname.label')}
            error={touched.firstname && errors.firstname}
            value={values.firstname}
            onChange={handleChange}
            noBorder={!hasBorder}
            required
          />
        </div>
        <div className="md:col-6 mb-10">
          <Input
            id="lastname"
            label={t('components.register.lastname.label')}
            error={touched.lastname && errors.lastname}
            value={values.lastname}
            onChange={handleChange}
            noBorder={!hasBorder}
            required
          />
        </div>
        <div className="md:col-6 mb-10">
          <Input
            id="email"
            label={t('components.register.email.label')}
            type="email"
            error={touched.email && errors.email}
            value={values.email}
            onChange={handleChange}
            noBorder={!hasBorder}
            required
          />
        </div>
        <div className="md:col-6 mb-10">
          <Input
            id="password"
            label={t('components.register.password.label')}
            type="password"
            error={touched.password && errors.password}
            value={values.password}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }}
            noBorder={!hasBorder}
            required
          />
        </div>
        <div className="col-12 mt-5">
          <Button
            type="submit"
            size="xl"
            className="w-full"
            loading={isSubmitting}
          >
            {t('components.register.button')}
          </Button>
        </div>
        {alert ? (
          <div className="col-12 mt-15">
            <Alert messages={alert.messages} type={alert.type} />
          </div>
        ) : null}
      </div>
    </form>
  )
}

export default RegisterComponent
