import { createContext, useCallback, useEffect, useState } from 'react'
import useSWR from 'swr'

import api from '@/services/api'

export const UserContext = createContext()

export function UserProvider({ children, value }) {
  const { data, mutate, error } = useSWR('/me', {
    fallbackData: { data: value },
    shouldRetryOnError: false,
  })

  const mutateUser = (newData, ...rest) => mutate({ data: newData }, ...rest)
  const user = data?.data
  const isFlightClub = user?.subscriptions?.includes('flightclub')

  // admin
  const [powers, setPowers] = useState({})
  const isUlysse =
    user?.email?.endsWith('ulysse.com') ||
    user?.email?.endsWith('ulysse.travel')

  useEffect(() => {
    if (
      error?.message === 'unauthenticated' ||
      error?.message === 'invalid_token'
    ) {
      api.auth.logout().then(() => {
        mutateUser(null, false)

        if (user) {
          window.location.reload()
        }
      })
    }
  })

  const setPower = useCallback(
    (code, value) =>
      isUlysse ? setPowers((old) => ({ ...old, [code]: value })) : null,
    [setPowers, isUlysse]
  )

  return (
    <UserContext.Provider
      value={{
        user,
        mutateUser,
        isFlightClub,
        admin: {
          is: isUlysse,
          powers: powers,
          setPower,
        },
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
