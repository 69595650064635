import { LuggageService } from './Service'

const buildLabel = (lugageList) => {
  const composition = lugageList
    .map((L) => {
      return L.maximum_weight_kg
        ? `${L.quantity} x ${L.maximum_weight_kg}kg`
        : `x${L.quantity}`
    })
    .join(' + ')

  return composition
}

class LugageBundle extends LuggageService {
  constructor(service) {
    super(service)
  }

  listLabel(id) {
    const option = this.meta_data.option_list.find((O) => O.id === id)
    return buildLabel(option.baggage_list)
  }

  get selectedOption() {
    return this.meta_data.option_list.find(
      (O) => O.id === this.meta_data.selected_option_id
    )
  }

  get selectedListLabel() {
    return buildLabel(this.selectedOption.baggage_list)
  }

  get count() {
    const option = this.selectedOption
    return option
      ? option.baggage_list.reduce((acc, l) => acc + l.quantity, 0)
      : 0
  }

  get numberOfOptions() {
    return this.meta_data.option_list?.length || 0
  }
}

export default LugageBundle
