import { useRouter } from 'next/router'
import { createContext, useState, useEffect } from 'react'
import { usePreventScroll } from '@react-aria/overlays'

export const ScrollContext = createContext()

export function ScrollProvider({ children }) {
  const [shouldPreventScroll, setShouldPreventScroll] = useState(false)

  // We're using react-aria's usePreventScroll in this provider because it's
  // buggy when used inside the modal component (navigating away from multiple
  // open modals doesn't remove `overflow: hidden` from the html element), so
  // users can't scroll on the next page.
  usePreventScroll({ isDisabled: !shouldPreventScroll })

  const preventScroll = () => {
    setShouldPreventScroll(true)
  }

  const allowScroll = () => {
    setShouldPreventScroll(false)
  }

  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeComplete', allowScroll)
    return () => {
      router.events.off('routeChangeComplete', allowScroll)
    }
  })

  return (
    <ScrollContext.Provider value={{ preventScroll, allowScroll }}>
      {children}
    </ScrollContext.Provider>
  )
}
