import { addDays, isBefore } from 'date-fns'
import StableLugageBundle from './StableLugageBundle'
import LugageBundle from './LugageBundle'
import Koala from './Koala'
import DisruptedFlightInsurance from './DisruptedFlightInsurance'
import ComprehensiveInsurance from './ComprehensiveInsurance'
import CheckInAuto from './CheckInAuto'
import Service from './Service'
import Slice from './Slice'

const getUniqueServices = (order) =>
  (order.passengers || []).reduce((services, P) => {
    P.services.forEach((pService) => {
      if (!services.some((service) => service.id === pService.id)) {
        services.push(pService)
      }
    })

    return services
  }, [])

const isKoala = (service) =>
  service.type === 'insurance' &&
  service.meta_data?.name === 'koala' &&
  service.meta_data?.insurance_type === 'koala'

const isDisruptedFlightInsurance = (service) =>
  service.type === 'insurance' &&
  service.meta_data?.name === 'koala' &&
  service.meta_data?.insurance_type === 'disrupted_flight_compensation'

const isComprehensiveInsurance = (service) =>
  service.type === 'insurance' &&
  service.meta_data?.name === 'koala' &&
  service.meta_data?.insurance_type === 'comprehensive'

class Order {
  slices // Array of slices
  passengers // Array of passengers

  id
  ulysse_id
  client_email
  client_phone
  is_recent_cancellable_booking
  total_amount
  total_currency

  current_user_role // owner or participant

  constructor(order) {
    const formatOrder = {
      ...order,
      slices: Array.isArray(order?.slices)
        ? order.slices.map((s) => new Slice(s, getUniqueServices(order)))
        : order?.slices,
      passengers: order?.passengers.map((P) => {
        return {
          ...P,
          services: P.services.map((S) => {
            if (S.type === 'stable_baggage_bundle') {
              return new StableLugageBundle(S)
            }
            if (S.type === 'baggage_bundle') {
              return new LugageBundle(S)
            }
            if (isKoala(S)) {
              return new Koala(S)
            }
            if (isDisruptedFlightInsurance(S)) {
              return new DisruptedFlightInsurance(S)
            }
            if (isComprehensiveInsurance(S)) {
              return new ComprehensiveInsurance(S)
            }
            if (S.type === 'check_in_auto') {
              return new CheckInAuto(S)
            }
            return new Service(S)
          }),
        }
      }),
      koala:
        order && getUniqueServices(order).some(isKoala)
          ? new Koala(getUniqueServices(order).find(isKoala))
          : undefined,
      disruptedFlightInsurance:
        order && getUniqueServices(order).some(isDisruptedFlightInsurance)
          ? new DisruptedFlightInsurance(
              getUniqueServices(order).find(isDisruptedFlightInsurance)
            )
          : undefined,
      comprehensiveInsurance:
        order && getUniqueServices(order).some(isComprehensiveInsurance)
          ? new ComprehensiveInsurance(
              getUniqueServices(order).find(isComprehensiveInsurance)
            )
          : undefined,
    }
    Object.assign(this, formatOrder)
  }

  get isOneway() {
    return this.slices.length === 1
  }

  get isRoundtrip() {
    return (
      this.slices.length === 2 &&
      this.firstSlice.origin.city_name === this.lastSlice.destination.city_name
    )
  }

  get isMulti() {
    return this.slices.length >= 2 && !this.isRoundtrip
  }

  get firstSlice() {
    return this.slices?.[0]
  }

  get lastSlice() {
    return this.slices?.[this.slices?.length - 1]
  }

  get departureDate() {
    return this.firstSlice?.departureDate
  }

  get departureLocalDate() {
    return this.firstSlice?.departureLocalDate
  }

  get departureZonedDate() {
    return this.firstSlice?.departureZonedDate
  }

  get arrivalDate() {
    return this.lastSlice?.arrivalDate
  }

  get arrivalLocalDate() {
    return this.lastSlice?.arrivalLocalDate
  }

  get arrivalZonedDate() {
    return this.lastSlice?.arrivalZonedDate
  }

  get isPast() {
    return isBefore(this.arrivalZonedDate, new Date())
  }

  get isFuture() {
    return isBefore(new Date(), this.departureZonedDate)
  }

  get isInProgress() {
    return !this.isPast && !this.isFuture
  }

  get isCancelled() {
    return (
      this.booking_status === 'cancelled' ||
      this.booking_status === 'cancelled_with_koala' ||
      this.booking_status === 'refund_requested'
    )
  }

  get numberOfCheckInLuggage() {
    return (
      (this.passengers || []).reduce((sum, p) => {
        const luggageService = (p?.services || []).find(
          (s) => s.type === 'baggage'
        )
        return luggageService ? sum + luggageService.quantity : sum
      }, 0) || 0
    )
  }

  get nextFlight() {
    const futureSlices = this.slices
      .filter((s) => s.isFuture)
      .sort((a, b) => a.departureZonedDate - b.departureZonedDate)

    return futureSlices?.[0]
  }

  get prevFlight() {
    const pastSlices = this.slices
      .filter((s) => s.isPast)
      .sort((a, b) => b.departureZonedDate - a.departureZonedDate)

    return pastSlices?.[0]
  }

  get nextOrLastFlight() {
    return this.nextFlight ?? this.slices[this.slices.length - 1]
  }

  get isActive() {
    return (
      this.booking_status === 'active' ||
      this.booking_status === 'modified' ||
      this.booking_status === 'schedule_change'
    )
  }

  getMarketingAirlineCodes() {
    return new Set(
      this.slices
        .flatMap((s) => s.segments)
        .map((s) => s.marketing_carrier_code)
    )
  }

  computePassengerCountsPerType() {
    return this.passengers
      .map((p) => p.type)
      .reduce((counts, type) => {
        counts[type] = (counts[type] ?? 0) + 1
        return counts
      }, {})
  }

  isDepartureDateInLessThanDays(days) {
    return isBefore(this.departureZonedDate, addDays(new Date(), days))
  }
}

export default Order
