import * as core from '@/v2/api/fetcher'
import calendar from '@/v2/api/calendar'
import ancv from '@/v2/api/ancv'

import auth from './auth'
import checkout from './checkout'
import gift from './gift'
import order from './order'
import search from './search'
import feedback from './feedback'
import postBookingOrder from './post-booking-order'
import b2b from './b2b'
import newsletter from './newsletter'
import partnerships from './partnerships'

export const apiFactory = (fetcher) => ({
  auth: auth(fetcher),
  checkout: checkout(fetcher),
  gift: gift(fetcher),
  order: order(fetcher),
  search: search(fetcher),
  feedback: feedback(fetcher),
  postBookingOrder: postBookingOrder(fetcher),
  ancv: ancv(fetcher),
  b2b: b2b(fetcher),
  newsletter: newsletter(fetcher),
  partnerships: partnerships(fetcher),
  calendar: calendar(fetcher),
  fetcher,
})

// TODO feature flag
const api = apiFactory(core.fetcher)

export default api
