import { useRouter } from 'next/router'
import useTranslation from '@/hooks/translation'

const useCurrency = () => {
  const router = useRouter()
  const locale = router.locale

  const { t } = useTranslation()

  return {
    availableCurrencies: [
      { value: 'EUR', symbol: '€', name: t('hooks.currency.eur') },
      { value: 'USD', symbol: '$', name: t('hooks.currency.usd') },
      { value: 'GBP', symbol: '£', name: t('hooks.currency.gbp') },
    ],
    formatCurrency: (number, currency, options) => {
      if (!number && !currency) return null

      const formattedNumber =
        typeof number === 'string' ? Number(number) : number
      let localeCurrency = null

      const minimumFractionDigits = options?.minimumFractionDigits ?? 0

      try {
        localeCurrency = new Intl.NumberFormat(locale, {
          style: 'currency',
          currency,
          currencyDisplay: 'narrowSymbol',
          minimumFractionDigits,
        }).format(formattedNumber)
      } catch (err) {
        localeCurrency = new Intl.NumberFormat(locale, {
          style: 'currency',
          currency,
          currencyDisplay: 'symbol',
          minimumFractionDigits,
        }).format(formattedNumber)
      }

      return localeCurrency
    },
  }
}

export default useCurrency
