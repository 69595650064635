import { useCallback, useContext } from 'react'
import {
  AnalyticsContext,
  AnalyticsPropertyContext,
} from '@/providers/analytics'

function mergeProps(fromContext, fromEvent) {
  if (fromContext && fromEvent) {
    return { ...fromContext, ...fromEvent }
  }
  return fromContext ? fromContext : fromEvent
}

// FIXME use a ts type to ensure we do the right thing
const useAnalytics = () => {
  const ctxProps = useContext(AnalyticsPropertyContext)
  const context = useContext(AnalyticsContext)
  const serialized = JSON.stringify(ctxProps)

  const trackWithElytics = useCallback(
    (eventName, props) =>
      context.trackWithElytics(eventName, mergeProps(ctxProps, props)),
    [serialized]
  )

  const track = useCallback(
    (eventName, props) => context.track(eventName, mergeProps(ctxProps, props)),
    [serialized]
  )

  // we do not override identify properties
  // FIXME improve the provider to override various contexts
  return { track, identify: context.identify, trackWithElytics }
}

export default useAnalytics
