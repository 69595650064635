import { useCallback } from 'react'
import { useIsSSR } from '@react-aria/ssr'

function readAndParse(key) {
  const v = localStorage.getItem(key)
  return v !== null && v !== undefined ? JSON.parse(v) : null
}

function serializedAndStore(key, value) {
  if (value === null || value === undefined) {
    localStorage.removeItem(key)
  }
  localStorage.setItem(key, JSON.stringify(value))
}

export const useLocalStorage = (key) => {
  const isSSR = useIsSSR()

  const readItem = useCallback(() => {
    if (!isSSR && key) {
      return readAndParse(key)
    }
    return null
  }, [isSSR, key])

  const setItem = useCallback(
    (value) => {
      if (!isSSR && key) {
        if (typeof value === 'function') {
          // read the old value
          value = value(readAndParse(key))
        }

        serializedAndStore(key, value)
      }
    },
    [isSSR, key]
  )

  const removeItem = useCallback(() => setItem(null), [setItem])

  return {
    readItem,
    setItem,
    removeItem,
  }
}
