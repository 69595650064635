import { useRouter } from 'next/router'
import { DARK_PAGES } from '@/components/constants'

// Components
import Navigation from '@/components/Navigation'
import FooterComponent from '@/components/Footer'

function DefaultLayout({ children }) {
  const { route } = useRouter()
  const isDark = DARK_PAGES.some((P) => P === route)

  return (
    <div>
      <div className="bg-white relative z-px">
        <Navigation isDark={isDark} />
        {children}
      </div>
      <FooterComponent />
    </div>
  )
}

export default DefaultLayout
