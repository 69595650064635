import { useState, forwardRef } from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import { useFocusRing } from '@react-aria/focus'
import { mergeProps } from '@react-aria/utils'

// Styles
import styles from './index.module.css'

// Hooks
import useAnalytics from '@/hooks/analytics'

export const isExternalUrl = (url) =>
  /^(^https?:\/\/.*)|(^mailto:.*)|(^tel:.*)/i.test(url)

// eslint-disable-next-line react/display-name
const Button = forwardRef(
  (
    {
      children,
      className,
      color,
      outline,
      size,
      href,
      replace,
      locale,
      disabled,
      plain,
      loading,
      trackingEventName,
      unstyled,
      ...rest
    },
    ref
  ) => {
    const { track } = useAnalytics()
    const [isLoading, setIsLoading] = useState(false)

    const handleClick = async (e) => {
      if (rest.onClick) {
        const result = rest.onClick(e)
        if (trackingEventName) {
          track(trackingEventName)
        }
        if (result instanceof Promise) {
          setIsLoading(true)
          try {
            await result
          } catch (e) {} // eslint-disable-line no-empty
          setIsLoading(false)
        }
      }
    }

    const { isFocusVisible, focusProps } = useFocusRing()

    const classes = clsx(
      styles['reset-focus'],
      !unstyled && styles.btn,
      !unstyled && styles[color ? color : 'primary'],
      {
        [styles.focus]: isFocusVisible && !disabled,
        [styles.outline]: outline,
        [styles.plain]: plain,
        [styles[size]]: size,
        [styles.disabled]: disabled,
      },
      { [styles.loading]: isLoading || loading },
      className
    )

    const props = mergeProps(rest, focusProps, { ref })

    return href && isExternalUrl(href) ? (
      <a
        {...props}
        className={classes}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          if (trackingEventName) {
            track(trackingEventName)
          }
          if (handleClick) {
            handleClick()
          }
        }}
      >
        {children}
      </a>
    ) : href ? (
      <Link
        href={href}
        replace={replace}
        locale={locale}
        passHref
        {...props}
        className={classes}
        onClick={() => {
          if (trackingEventName) {
            track(trackingEventName)
          }
        }}
      >
        {children}
      </Link>
    ) : (
      <button
        type="button"
        {...props}
        disabled={disabled || loading || isLoading}
        onClick={handleClick}
        className={classes}
      >
        {children}
      </button>
    )
  }
)

export default Button
