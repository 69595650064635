// eslint-disable-next-line no-restricted-imports
import NextHead from 'next/head'
import useBreakpoints from '@/hooks/breakpoints'
import { useRouter } from 'next/router'

const baseUrl = 'https://ulysse.com'

const useGetUrl = () => {
  const router = useRouter()

  const path = Object.keys(router.query || {}).reduce(
    (replacedPathname, key) =>
      replacedPathname.replace(`[${key}]`, router.query[key]),
    router.pathname
  )

  return (locale) =>
    `${baseUrl}${locale !== router.defaultLocale ? `/${locale}` : ''}${
      path === '/' ? '' : path
    }`
}

const routerLocaleToSeoLocale = {
  fr: 'fr_FR',
  en: 'en_US',
  es: 'es_ES',
}

function Head({ children, title, description, image }) {
  const router = useRouter()
  const getUrl = useGetUrl()
  const url = getUrl(router.locale)
  const { isMobile, isTablet } = useBreakpoints()

  return (
    <NextHead>
      {title ? <title>{title}</title> : null}
      {description ? <meta name="description" content={description} /> : null}

      {/* Open Graph */}
      {title ? <meta property="og:title" content={title} /> : null}
      {description ? (
        <meta property="og:description" content={description} />
      ) : null}
      {image ? <meta property="og:image" content={image} /> : null}
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Ulysse" />
      <meta property="og:url" content={url} />

      {router.locales.map((locale) => (
        <meta
          key={locale}
          property={`og:locale${locale !== router.locale ? ':alternate' : ''}`}
          content={routerLocaleToSeoLocale[locale]}
        />
      ))}

      {/* Twitter */}
      {title ? <meta property="twitter:title" content={title} /> : null}
      {description ? (
        <meta property="twitter:description" content={description} />
      ) : null}
      <meta name="twitter:site" content="@ulyssetravel" />
      {image ? <meta property="twitter:image" content={image} /> : null}

      {router.locales.map((locale) => (
        <link
          key={locale}
          rel="alternate"
          hrefLang={locale}
          href={getUrl(locale)}
        />
      ))}

      <link
        rel="alternate"
        hrefLang="x-default"
        href={getUrl(router.defaultLocale)}
      />

      <link rel="canonical" href={url} />

      {isMobile || isTablet ? (
        <meta name="theme-color" content="white" />
      ) : (
        <meta name="theme-color" content="#316CE2" />
      )}

      <link rel="icon" href="/favicon.ico" />

      {children}
    </NextHead>
  )
}

export default Head
