import { cloneElement, useRef, forwardRef } from 'react'
import { useOverlayTriggerState } from '@react-stately/overlays'
import {
  useOverlay,
  useOverlayTrigger,
  useOverlayPosition,
  useModal,
  OverlayContainer,
} from '@react-aria/overlays'
import { Transition } from 'react-transition-group'
import { useDialog } from '@react-aria/dialog'
import { FocusScope } from '@react-aria/focus'
import { useIsSSR } from '@react-aria/ssr'
import { mergeProps } from '@react-aria/utils'

export const Popover = forwardRef(
  (
    {
      state,
      transitionState,
      children,
      onClose,
      shouldCloseOnInteractOutside,
      contentProps,
    },
    ref
  ) => {
    const { overlayProps } = useOverlay(
      {
        isOpen: state.isOpen,
        onClose,
        isDismissable: true,
        shouldCloseOnInteractOutside,
      },
      ref
    )

    const { modalProps } = useModal()
    const { dialogProps, titleProps } = useDialog({}, ref)

    return (
      <FocusScope restoreFocus contain autoFocus>
        <div
          {...mergeProps(overlayProps, dialogProps, contentProps, modalProps)}
          ref={ref}
        >
          {typeof children === 'function'
            ? children({
                titleProps,
                contentStyle: contentProps?.style,
                transitionState,
                ...state,
              })
            : children || null}
        </div>
      </FocusScope>
    )
  }
)

Popover.displayName = 'Popover'

function isChildOf(parent, child) {
  let element = child
  while (element != null) {
    if (element === parent) {
      return true
    }
    element = element.parentElement
  }

  return false
}

export default function PopoverContainer(props) {
  const isSSR = useIsSSR()

  const localTriggerRef = useRef()
  const triggerRef = props.triggerRef || localTriggerRef
  const overlayRef = useRef()

  const localTriggerState = useOverlayTriggerState({})
  const state = props.triggerState ?? localTriggerState

  let { triggerProps, overlayProps } = useOverlayTrigger(
    { type: props.overlayType ?? 'dialog' },
    state,
    triggerRef
  )

  // FIXME should we always remove the onPress ???
  delete triggerProps['onPress']

  let { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    placement: props.placement || 'bottom left',
    offset: 5,
    isOpen: state.isOpen,
  })

  const trigger =
    typeof props?.trigger === 'function'
      ? props.trigger({ ...state })
      : props?.trigger

  return (
    <>
      {trigger
        ? cloneElement(
            trigger,
            mergeProps(props.trigger.props, triggerProps, {
              ref: triggerRef,
              onClick: () => {
                state.toggle()
              },
            })
          )
        : null}
      {!isSSR ? (
        <Transition
          nodeRef={overlayRef}
          in={state.isOpen}
          timeout={props.timeout || 0}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          {(transitionState) => {
            return (
              <OverlayContainer className={props.overlayClassName}>
                <Popover
                  {...props}
                  ref={overlayRef}
                  contentProps={mergeProps(overlayProps, positionProps)}
                  state={state}
                  transitionState={transitionState}
                  shouldCloseOnInteractOutside={(e) =>
                    !isChildOf(triggerRef.current, e)
                  }
                  onClose={() => state.close()}
                />
              </OverlayContainer>
            )
          }}
        </Transition>
      ) : null}
    </>
  )
}
