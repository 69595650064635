import { useRouter } from 'next/router'
/* eslint-disable no-restricted-imports */
import {
  format,
  formatDistance,
  formatDistanceStrict,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  formatDuration,
  formatRelative,
  intervalToDuration,
} from 'date-fns'
/* eslint-enable no-restricted-imports */
import fr from 'date-fns/locale/fr'
import es from 'date-fns/locale/es'
import it from 'date-fns/locale/it'
import de from 'date-fns/locale/de'
import da from 'date-fns/locale/da'
import enUS from 'date-fns/locale/en-US'

const supportedDateFnsLocales = { fr, es, en: enUS, it, de, da }

const useDates = () => {
  const router = useRouter()
  const locale = router.locale

  return {
    formatDate: (date, dateFormat, options) =>
      format(date, dateFormat, {
        ...options,
        locale: supportedDateFnsLocales[locale],
      }),
    formatDistance: (date, baseDate, options) =>
      formatDistance(date, baseDate, {
        ...options,
        locale: supportedDateFnsLocales[locale],
      }),
    formatDistanceStrict: (date, baseDate, options) =>
      formatDistanceStrict(date, baseDate, {
        ...options,
        locale: supportedDateFnsLocales[locale],
      }),
    formatDistanceToNow: (date, options) =>
      formatDistanceToNow(date, {
        ...options,
        locale: supportedDateFnsLocales[locale],
      }),
    formatDistanceToNowStrict: (date, options) =>
      formatDistanceToNowStrict(date, {
        ...options,
        locale: supportedDateFnsLocales[locale],
      }),
    formatDuration: (duration, options) =>
      formatDuration(duration, {
        ...options,
        locale: supportedDateFnsLocales[locale],
      }),
    formatHourlyDuration: (mins) => {
      const { days, hours, minutes } = intervalToDuration({
        start: 0,
        end: mins * 60_000,
      })

      let hoursWithDay = hours + days * 24

      return `${hoursWithDay ? hoursWithDay + 'h' : ''}${
        minutes ? minutes + 'm' : ''
      }`
    },
    // Long format is better for screen readers since 2h30m read out loud can
    // be confusing
    formatLongHourlyDuration: (mins) => {
      const { days, hours, minutes } = intervalToDuration({
        start: 0,
        end: mins * 60_000,
      })

      if (locale === 'en') {
        return [
          days && days > 1 ? days + ' days' : days ? days + ' day' : undefined,
          hours && hours > 1
            ? hours + ' hours'
            : hours
            ? hours + ' hour'
            : undefined,
          minutes && minutes > 1
            ? minutes + ' minutes'
            : minutes
            ? minutes + ' minute'
            : undefined,
        ]
          .filter(Boolean)
          .join(' ')
      }

      return [
        days && days > 1 ? days + ' jours' : days ? days + ' jour' : undefined,
        hours && hours > 1
          ? hours + ' heures'
          : hours
          ? hours + ' heure'
          : undefined,
        minutes && minutes > 1
          ? minutes + ' minutes'
          : minutes
          ? minutes + ' minute'
          : undefined,
      ]
        .filter(Boolean)
        .join(' ')
    },
    formatRelative: (date, baseDate, options) =>
      formatRelative(date, baseDate, {
        ...options,
        locale: supportedDateFnsLocales[locale],
      }),
    getDateFromString: (value) => {
      if (value && typeof value === 'string') {
        const parts = value.split('/')
        return new Date(
          Number(parts[2]),
          Number(parts[1]) - 1,
          Number(parts[0])
        )
      }

      return value
    },
  }
}

export default useDates
