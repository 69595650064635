import { reportError } from '@/utils/error'

const doNotReportThese = [401, 404, 410]

export const fetcher = (endpoint, opts = {}) => {
  const apiUrl = opts.api || '/api'
  const body = JSON.stringify(opts.body || undefined)
  const method = opts.method || 'GET'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...opts.headers,
  }

  return fetch(`${apiUrl}${endpoint}`, {
    method: method || 'GET',
    body,
    headers,
  }).then(async (R) => {
    if (R.ok === false) {
      const result = {
        status: R.status,
        statusText: R.statusText,
      }

      try {
        result.content = await R.json()
        // for compatbilitity reasons, if content contains a data, we expose it
        if (result.content?.data) {
          result.data = result.content.data
        }
      } catch (e) {
        // not a json field
        result.content = null
      }

      // Don't report 401s, 404s, etc. since that's the expected behavior
      if (!doNotReportThese.includes(R?.status)) {
        reportError(
          new Error(`Failed to fetch ${method} ${apiUrl}${endpoint}`),
          {
            extra: {
              status: R.status,
              statusText: R.statusText,
              ok: R.ok,
              url: R.url,
              response: result.content || R,
            },
          }
        )
      }

      throw result
    }

    try {
      const r = await R.json()
      return r
    } catch (e) {
      reportError(new Error(`Failed to parse ${apiUrl}${endpoint} response`), {
        extra: {
          status: R.status,
          statusText: R.statusText,
          ok: R.ok,
          url: R.url,
          response: R,
          error: e,
        },
      })
      throw e
    }
  })
}
