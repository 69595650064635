import styles from './index.module.css'
import Cross from '@/assets/svg/circle-cross-v2.svg'
import Check from '@/assets/svg/circle-check-v2.svg'
import Train from '@/assets/svg/warningTrain.svg'
import Info from '@/assets/svg/circle-info.svg'
import Warning from '@/assets/svg/circle-warning.svg'
import { forwardRef } from 'react'

const Alert = (
  { type = 'neutral', messages, className, children, isTight, role },
  ref
) => (
  <div className={`rounded ${className}`} role={role} ref={ref}>
    <div
      className={`flex ${isTight ? 'p-10' : 'p-15'} rounded ${styles[type]}`}
    >
      {type === 'neutral' ? (
        <Info
          className={`text-primary flex-shrink-0 ${
            isTight ? 'mr-10' : 'mr-15'
          }`}
        />
      ) : type === 'error' ? (
        <Cross
          className={`text-red flex-shrink-0 w-[24px] ${
            isTight ? 'mr-10' : 'mr-15'
          }`}
        />
      ) : type === 'warning' ? (
        <Warning
          className={`text-warning-dark flex-shrink-0 ${
            isTight ? 'mr-10' : 'mr-15'
          }`}
        />
      ) : type === 'success' ? (
        <Check
          className={`text-green flex-shrink-0 ${isTight ? 'mr-10' : 'mr-15'}`}
          width={24}
          height={24}
        />
      ) : type === 'warning-train' ? (
        <Train
          className={`text-black flex-shrink-0 ${isTight ? 'mr-10' : 'mr-15'}`}
          width={14}
          height={19}
        />
      ) : null}
      {messages?.length ? (
        <ul className="mt-[2px]">
          {messages.map((M, i) => (
            <li key={i} className="mb-10 last:mb-0">
              <p className="text-14">{M}</p>
            </li>
          ))}
        </ul>
      ) : (
        children
      )}
    </div>
  </div>
)

export default forwardRef(Alert)
