import useSWR from 'swr'
import Order from '@/logic/Order'
import { parseISO } from 'date-fns'

function useTrips({ user }) {
  const { data, error, mutate } = useSWR(user ? '/me/orders' : null)

  const trips = data?.data?.map((O) => new Order(O)) || []

  const cancelledTrips = trips?.filter((trip) => trip.isCancelled) || []

  const pastTrips =
    trips
      ?.filter((trip) => trip.isPast && !trip.isCancelled)
      ?.sort((a, b) => b.departureZonedDate - a.departureZonedDate) || []

  const upcomingTrips =
    trips
      ?.filter((trip) => trip.isFuture && !trip.isCancelled)
      ?.sort((a, b) => a.departureZonedDate - b.departureZonedDate) || []

  const inProgressTrips =
    trips
      ?.filter((trip) => trip.isInProgress && !trip.isCancelled)
      ?.sort((a, b) => a.departureZonedDate - b.departureZonedDate) || []

  const nextTrip =
    trips
      ?.filter(
        (trip) => (trip.isInProgress || trip.isFuture) && !trip.isCancelled
      )
      ?.sort((a, b) => a.departureZonedDate - b.departureZonedDate)?.[0] ||
    undefined

  const nextSlices =
    trips?.length > 0
      ? trips
          .flatMap((trip) =>
            trip.slices.map((slice) => slice.setTripId(trip.id))
          )
          .filter((s) => s.isFuture)
          .sort((a, b) => parseISO(a.departureDate) - parseISO(b.departureDate))
      : []

  const nextSlice = nextSlices?.length ? nextSlices[0] : null

  return {
    trips,
    cancelledTrips,
    pastTrips,
    upcomingTrips,
    inProgressTrips,
    nextTrip,
    nextSlices,
    nextSlice,
    isLoading: !error && !data,
    isError: error,
    mutateTrips: mutate,
  }
}

export default useTrips
