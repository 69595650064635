import {
  ANCVCheck,
  ANCVDetailedRequest,
  ANCVRefundableItem,
  ANCVRequest,
} from '../ancv/ANCVRequest'
import { Fetcher, HomerFetcher, HomerResponse } from './fetcher'

/**
 * Request as
 */
export type ANCVRequestCreation = {
  company: string
  checks: Array<ANCVCheck>
  refundable_items: Array<ANCVRefundableItem>
}

const ancv = (fetcher: Fetcher<unknown>) => ({
  createRequest: (
    creation: ANCVRequestCreation
  ): Promise<HomerResponse<ANCVRequest>> => {
    return (fetcher as HomerFetcher<ANCVRequest>)('/ancv_requests', {
      method: 'POST',
      body: creation,
    })
  },

  getRequest: (id: string): Promise<HomerResponse<ANCVDetailedRequest>> => {
    return (fetcher as HomerFetcher<ANCVDetailedRequest>)(
      `/ancv_requests/${id}`
    )
  },

  updateTrackingCode: (
    id: string,
    code: string
  ): Promise<HomerResponse<ANCVDetailedRequest>> => {
    return (fetcher as HomerFetcher<ANCVDetailedRequest>)(
      `/ancv_requests/${id}/tracking_info`,
      {
        method: 'PUT',
        body: {
          carrier_tracking_number: code,
        },
      }
    )
  },
})

export default ancv
