export function initRudderStack() {
  var e = (window.rudderanalytics = window.rudderanalytics || [])
  ;(e.methods = [
    'load',
    'page',
    'track',
    'identify',
    'alias',
    'group',
    'ready',
    'reset',
    'getAnonymousId',
    'setAnonymousId',
  ]),
    (e.factory = function (t) {
      return function () {
        var r = Array.prototype.slice.call(arguments)
        return r.unshift(t), e.push(r), e
      }
    })
  for (var t = 0; t < e.methods.length; t++) {
    var r = e.methods[t]
    e[r] = e[r] || e.factory(r)
  }
  e.loadJS = function () {
    var r = document.createElement('script')
    ;(r.type = 'text/javascript'),
      (r.async = !0),
      (r.src = 'https://cdn.rudderlabs.com/v1/rudder-analytics.min.js')
    var a = document.getElementsByTagName('script')[0]
    a.parentNode.insertBefore(r, a)
  }

  e.load(
    process.env.NEXT_PUBLIC_RUDDER_STACK_WRITE_KEY,
    process.env.NEXT_PUBLIC_RUDDER_STACK_URL
  )
}

export function bootstrapRudderStack() {
  if (!window.rudderanalytics) {
    initRudderStack()
  }

  if (!window.rudderanalytics) {
    console.warn('unable to find rudderstack')
    return
  }

  window.rudderanalytics.loadJS()
}
