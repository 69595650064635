import { useState, useEffect } from 'react'

const useBreakpoints = () => {
  // Recommended by the React team but to use with caution as component using this hook initially render twice
  const [isClient, setIsClient] = useState(undefined)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const [width, setWidth] = useState(
    typeof window === 'undefined' ? undefined : window.innerWidth
  )

  const updateWidth = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    updateWidth()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', updateWidth)
    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  })

  return {
    isDesktop: isClient ? width > 768 : true, // FIXME screeming
    isMediumDesktop: isClient && width <= 1280,
    isSmallDesktop: isClient && width <= 1024,
    isTablet: isClient && width <= 768,
    isMobile: isClient && width <= 640,
  }
}

export default useBreakpoints
