import { useState, useEffect, createContext } from 'react'
import { useRouter } from 'next/router'
import { getExperimentsCookie, setExperimentsCookie } from '@/utils/cookies'
import { useCallback } from 'react'

// the experiments context
export const ExperimentsContext = createContext({
  getExperiments: () => null,
  getExperimentByCode: () => 'a',
  setExperiment: () => null,
  isFlagActivated: () => false,
})

// cookie version
const VERSION = 1

/**
 * Read experiment values from query string
 *
 * @param {Object} experiments
 * @param {import('querystring').ParsedUrlQuery} query
 * @returns {Object} experiments read from query string
 */
function getExperimentsFromQuery(experiments = {}, query) {
  const e = Object.keys(experiments)
    .map((code) => [code, query?.['experiment-' + code]])
    .filter(([, v]) => v !== null && v !== undefined)
    .map(([code, value]) => [code, { activated: true, version: value }])

  return Object.fromEntries(e)
}

/**
 * Apply the rules as given into the exepriment provider description
 *
 * @param {Object} experiments
 * @param {Object} fromCookies
 * @param {Object} fromQuery
 */
function mergeExperiments(experiments = {}, fromCookies, fromQuery) {
  const e = Object.entries(experiments).map(([code, value]) => {
    /**
     * In this order :
     * - the value given by the url : experiment-code=
     * - the default value if the experiment is deactivated
     * - the value given by the cookie
     * - the default given value
     * - the deactivated version a
     */

    if (fromQuery[code]) {
      return [code, fromQuery[code]]
    }

    if (value.activated === false) {
      return [code, value]
    }

    if (fromCookies[code]) {
      return [code, fromCookies[code]]
    }

    if (value.version) {
      return [code, value]
    }

    return [code, { version: 'a', activated: false }]
  })

  return Object.fromEntries(e)
}

/**
 * Compare the two experiment objects
 *
 * @param {Object} oldExp old experiments
 * @param {Object} newExp new experiments
 */
function areEquals(oldExp, newExp) {
  // FIXME is comapring in JS faster?
  return JSON.stringify(oldExp) === JSON.stringify(newExp)
}

/**
 * Creates an experiment provider, with the experiments used for this context
 * and their default values and activation state.
 *
 * We use in this order :
 * - the value given by the url : experiment-code=
 * - the default value if the experiment is deactivated
 * - the value given by the cookie
 * - the default given value
 * - the version a (and this version is registered as deactivated)
 *
 * @returns {ExperimentsContext.Provider} an experiemnts provider
 */
export function ExperimentsProvider({
  experiments = {},
  flags = {},
  children,
}) {
  // FIXME in case of override, we could do that
  // const upContext = useContext(ExperimentsContext)
  const [experimentValues, setExperimentValues] = useState({})
  const router = useRouter()

  // load exeperiment values to use for this context
  useEffect(() => {
    const queryExperiments = getExperimentsFromQuery(experiments, router.query)
    const [version, cookieExperiments] = getExperimentsCookie()

    const merged = mergeExperiments(
      experiments,
      version < VERSION ? {} : cookieExperiments,
      queryExperiments
    )

    setExperimentValues((old) => {
      if (areEquals(old, merged)) {
        return old
      }
      return merged
    })
  }, [router.query, experiments, setExperimentValues])

  useEffect(() => {
    const [version, cookieExperiments] = getExperimentsCookie()
    if (version < VERSION) {
      setExperimentsCookie(VERSION, experimentValues)
    } else {
      setExperimentsCookie(VERSION, {
        ...cookieExperiments,
        ...experimentValues,
      })
    }
  }, [experimentValues])

  /**
   * Returns an experiment for the given code
   * Returns the given value, by default
   */
  const getExperimentByCode = useCallback(
    (code) => experimentValues?.[code]?.version || 'a',
    [experimentValues]
  )

  const getExperiments = useCallback(() => {
    if (!experimentValues) {
      return null
    }

    const entries = Object.keys(experimentValues).map((k) => [
      k,
      getExperimentByCode(k),
    ])

    return entries.length > 0 ? Object.fromEntries(entries) : null
  }, [experimentValues, getExperimentByCode])

  const setExperiment = useCallback(
    (code, value, activated = true) =>
      setExperimentValues((prev) => {
        if (
          prev[code]?.version === value &&
          prev[code]?.activated === activated
        ) {
          return prev
        }
        return { ...prev, [code]: { version: value, activated } }
      }),
    [setExperimentValues]
  )

  const isFlagActivated = useCallback((code) => flags[code] === true, [flags])

  return (
    <ExperimentsContext.Provider
      value={{
        getExperiments,
        getExperimentByCode,
        setExperiment,
        isFlagActivated,
      }}
    >
      {children}
    </ExperimentsContext.Provider>
  )
}

/**
 * Read a return active experiments from cookie
 */
export function getAllActiveExperiments() {
  const [version, ex] = getExperimentsCookie()
  if (version < VERSION) {
    return {}
  }

  const entries = Object.entries(ex)
    .filter(([, v]) => v.activated === true)
    .map(([k, v]) => [k, v.version])

  return entries.length > 0 ? Object.fromEntries(entries) : {}
}
