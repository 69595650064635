import { useState, forwardRef } from 'react'
import { useId } from '@react-aria/utils'
import clsx from 'clsx'
import Chevron from '@/assets/svg/chevron.svg'
import CircleCheck from '@/assets/svg/circle-check.svg'

function Select({
  label,
  parentClassname,
  className,
  noBorder,
  noBackground,
  options,
  value,
  forwardedRef,
  error,
  onBlur,
  isValid,
  isGroup,
  isFirst,
  isLast,
  alignArrowWithValue,
  disabled,
  hideLabel = false,
  ringClassName = 'ring-1 ring-dark-20 shadow',
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false)

  const id = useId(props.id)
  const errorId = useId(`error-${id}`)

  return (
    <>
      <div className={`relative min-w-80 w-full ${parentClassname || ''}`}>
        {hideLabel ? null : (
          <label
            htmlFor={id}
            className={`absolute z-20 left-15 transform motion-safe:transition-all motion-safe:duration-100 ${
              !value &&
              !options.find((o) => o.value === value || o.value === '')?.label
                ?.length
                ? 'text-base top-1/2 -translate-y-1/2'
                : 'text-12 top-[13px]'
            } text-dark-60 leading-none pointer-events-none`}
          >
            {label}
          </label>
        )}

        <select
          {...props}
          ref={forwardedRef}
          id={id}
          className={clsx(
            'relative w-full',
            noBackground ? '' : 'bg-white',
            disabled ? 'text-gray-60' : 'text-primary',
            'font-bold appearance-none focus:outline-none px-20 h-60',
            hideLabel ? '' : 'pt-15',
            isFocused ? 'ring-2 ring-primary z-10' : '',
            error ? 'ring-2 ring-danger' : '',
            noBorder ? '' : ringClassName,
            noBorder ? 'shadow' : '',
            className || '',
            isGroup
              ? isFirst
                ? 'rounded-l-5 rounded-r-none'
                : isLast
                ? 'rounded-r-5 rounded-l-none'
                : ''
              : 'rounded-5'
          )}
          aria-invalid={error ? 'true' : undefined}
          aria-describedby={error ? errorId : undefined}
          value={value}
          onFocus={() => {
            setIsFocused(true)
          }}
          onBlur={(e) => {
            if (onBlur) onBlur(e)
            setIsFocused(false)
          }}
          disabled={disabled}
        >
          {Array.isArray(options) &&
            options.map((option) => (
              <option
                value={option.value}
                key={id + '-' + (option.value || option.label)}
              >
                {option.label}
              </option>
            ))}
        </select>
        {isValid ? (
          <CircleCheck
            className={`absolute z-10 ${
              alignArrowWithValue
                ? 'bottom-16'
                : 'top-1/2 transform -translate-y-1/2'
            } right-20 text-primary`}
            width={15}
            height={15}
          />
        ) : (
          <span className="text-dark-60 pointer-events-none">
            <Chevron
              className={`w-12 absolute z-10 right-20 ${
                alignArrowWithValue
                  ? 'bottom-16'
                  : 'top-1/2 transform -translate-y-1/2'
              }`}
            />
          </span>
        )}
      </div>
      {error ? (
        <p className="text-danger text-14 mt-5" id={errorId}>
          {error}
        </p>
      ) : null}
    </>
  )
}

export default forwardRef(function WrappedSelect(props, ref) {
  return <Select {...props} forwardedRef={ref} />
})
