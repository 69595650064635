import Button from '@/components/ui/button'
import Google from '@/assets/svg/google.svg'
import api from '@/services/api'
import useUser from '@/hooks/user'
import useTranslation from '@/hooks/translation'
import useAnalytics from '@/hooks/analytics'

function GoogleAuth({ buttonColor = 'white', onSuccess }) {
  const { mutateUser } = useUser()
  const { track } = useAnalytics()
  const onGoogleAuth = () => {
    let timer = null

    let win = window.open(
      `${process.env.NEXT_PUBLIC_HOMER}/auth/google?state=${window.origin}/auth`,
      'ulysse-google-auth',
      'status=no, location=no, toolbar=no, menubar=no, width=500, height=700'
    )

    timer = setInterval(() => {
      if (win.closed) {
        clearInterval(timer)
        api.auth
          .me()
          .then(({ data }) => {
            if (data) {
              mutateUser({ id: data.email, ...data })
              track('account_login', { login_method: 'google' })
              if (onSuccess) {
                onSuccess()
              }
            }
          })
          .catch((err) => console.error(err))
      }
    }, 1000)
  }

  const { t } = useTranslation()

  return (
    <Button
      color={buttonColor}
      size="xl"
      className="w-full"
      onClick={() => onGoogleAuth()}
    >
      <Google className="w-20 h-20 mr-10" />
      {t('components.googleAuth.button')}
    </Button>
  )
}

export default GoogleAuth
