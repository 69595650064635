const search = (fetcher) => ({
  createSearch: (offer_request) =>
    fetcher('/offer_requests', {
      method: 'POST',
      body: { offer_request },
    }),
  getMultiOfferRecap: ({ offerRequestId, offers }) =>
    fetcher(`/offer_requests/${offerRequestId}/offers`, {
      method: 'POST',
      body: { offers },
    }),
  getOffers: (offerRequestId) => fetcher(`/offer_requests/${offerRequestId}`),
})

export default search
