const auth = (fetcher) => ({
  me: () => fetcher('/me'),
  signIn: (body) => fetcher('/sign_in', { method: 'POST', body }),
  signUp: ({ email, password, first_name, last_name }) =>
    fetcher('/sign_up', {
      method: 'POST',
      body: { email, password, first_name, last_name },
    }),
  logout: () => fetcher('/logout', { method: 'POST' }),
  requestPasswordReset: ({ email }) =>
    fetcher('/reset_password', {
      method: 'POST',
      body: { email },
    }),
  checkPasswordResetToken: ({ token }) =>
    fetcher(`/reset_password/${token}`, {
      api: process.env.NEXT_PUBLIC_HOMER_API,
    }),
  resetPassword: ({ token, password }) =>
    fetcher(`/reset_password/${token}`, {
      method: 'POST',
      body: { password },
    }),
  updateProfile: ({
    first_name,
    last_name,
    address_line,
    address_city,
    address_postal_code,
    address_country_code,
  }) =>
    fetcher('/me', {
      method: 'PUT',
      body: {
        first_name,
        last_name,
        address_line,
        address_city,
        address_postal_code,
        address_country_code,
      },
    }),
  addTravelerProfile: (profile) =>
    fetcher(`/me/profiles`, {
      method: 'POST',
      body: { profile },
    }),
  updateTravelerProfile: ({ profileId, profile }) =>
    fetcher(`/me/profiles/${profileId}`, {
      method: 'PUT',
      body: { profile },
    }),
  deleteProfile: (id) => fetcher(`/me/profiles/${id}`, { method: 'DELETE' }),
  updatePassword: ({ current_password, password }) =>
    fetcher('/me', {
      method: 'PUT',
      body: { current_password, password },
    }),
  updateEmail: ({ email, password }) =>
    fetcher('/me/change_email/', {
      method: 'POST',
      body: { email, current_password: password },
    }),
  checkChangeEmailToken: ({ token }) =>
    fetcher(`/me/change_email/${token}`, {
      method: 'POST',
    }),
  getUserSocketToken: () =>
    fetcher('/me/ticket', {
      method: 'POST',
    }),
})

export default auth
