import { parseISO, differenceInCalendarDays, isBefore } from 'date-fns'
import StableLugageBundle from './StableLugageBundle'
import LugageBundle from './LugageBundle'
import Service from './Service'
import { parseZonedDateTime } from '@internationalized/date'

const luggageTypeOrder = ['personal_item', 'carry_on', 'checked']

class Slice {
  origin
  destination

  constructor(slice, services) {
    const formatSlice = {
      ...slice,
      services: (services || [])
        .filter((service) => service.slices_ids.includes(slice.id))
        .map((S) => {
          if (S.type === 'stable_baggage_bundle') {
            return new StableLugageBundle(S)
          }

          if (S.type === 'baggage_bundle') {
            return new LugageBundle(S)
          }

          return new Service(S)
        }),
    }
    Object.assign(this, formatSlice)
  }

  /**
   * Set the trip identifier and returns this slice
   * @param {string} tripId the trip id
   * @returns this object updated
   */
  setTripId(tripId) {
    this.tripId = tripId
    return this
  }

  get boardingPass() {
    return this.documents?.find((D) => D.type === 'boarding_pass')
  }

  get firstSegment() {
    return this.segments?.[0]
  }

  get lastSegment() {
    return this.segments?.[this.segments?.length - 1]
  }

  get numberOfStops() {
    return this.segments?.length - 1
  }

  get departureDate() {
    return this.firstSegment?.departing_at
  }

  get departureTimezone() {
    return this.firstSegment?.origin?.timezone
  }

  get departureLocalDate() {
    return Slice.parseLocalDate(this.departureDate)
  }

  get departureZonedDate() {
    return Slice.parseZonedDate(this.departureDate, this.departureTimezone)
  }

  get arrivalDate() {
    return this.lastSegment?.arriving_at
  }

  get arrivalTimezone() {
    return this.lastSegment?.destination?.timezone
  }

  get arrivalLocalDate() {
    return Slice.parseLocalDate(this.arrivalDate)
  }

  get arrivalZonedDate() {
    return Slice.parseZonedDate(this.arrivalDate, this.arrivalTimezone)
  }

  get localCalendarDayDifference() {
    return differenceInCalendarDays(
      this.arrivalLocalDate,
      this.departureLocalDate
    )
  }

  /**
   * Difference of calendar days between local dates
   * @deprecated replaced by Slice.localCalendarDayDifference
   */
  get dayDifference() {
    return this.localCalendarDayDifference
  }

  get includedLugagesByPassenger() {
    const lugages = this.services.filter(
      (S) =>
        (S.type === 'stable_baggage_bundle' || S.type === 'baggage_bundle') &&
        S.is_included
    )
    const firstPassengerId = lugages[0]?.passengers_ids[0]

    return lugages
      .filter((L) => L.passengers_ids.includes(firstPassengerId))
      .sort(
        (a, b) =>
          luggageTypeOrder.indexOf(a.meta_data.type) -
          luggageTypeOrder.indexOf(b.meta_data.type)
      )
  }

  get airlines() {
    return this.segments
      ?.map((S) => ({
        code: S.marketing_carrier_code,
        name: S.marketing_carrier_name,
      }))
      .reduce((unique, item) => {
        return unique.find((I) => I.code === item.code)
          ? unique
          : [...unique, item]
      }, [])
  }

  get flightNumbers() {
    return this.segments.map(
      (S) =>
        `${S.operating_carrier_code || S.marketing_carrier_code}-${
          S.operating_carrier_flight_number || S.marketing_carrier_flight_number
        }`
    )
  }

  get isFuture() {
    return isBefore(new Date(), this.departureZonedDate)
  }

  get isPast() {
    return isBefore(this.arrivalZonedDate, new Date())
  }

  /**
   * Parse the given ISO date string as a locale date.
   * This date should be used to display a date as a local date.
   * It's discouraged to execute time functions on them.
   *
   * @param {string} string the locale ISO date to parse
   * @return a Date; the date is represented into the current timezone, but should not be considered as part of this timezone
   * @see Slice#parseZonedDate
   */
  static parseLocalDate(string) {
    return parseISO(string)
  }

  /**
   * Parse the given local date string into the given timezone (e.g. Europe/Paris).
   * Those dates are meant for time calculations.
   *
   * @param {string} string the locale ISO date to parse
   * @param {string} timezone the timezone
   * @return a Date object
   * @see Slice#parseLocaleDate to display local dates
   */
  static parseZonedDate(string, timezone) {
    return timezone
      ? parseZonedDateTime(string + `[${timezone}]`).toDate()
      : this.parseLocalDate(string)
  }
}

export default Slice
