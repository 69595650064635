const checkout = (fetcher) => ({
  createFlightClubSubscription: () =>
    fetcher('/flight_club', {
      method: 'POST',
    }),
  createCheckoutSession: (checkout_session) =>
    fetcher('/checkout_session', {
      method: 'POST',
      body: { checkout_session },
    }),
  createOrder: (selectedOffersIds) =>
    fetcher('/orders', {
      method: 'POST',
      body: { order: { selected_offers: selectedOffersIds } },
    }),
  editPassenger: ({ orderId, passengerId, passenger }) =>
    fetcher(`/orders/${orderId}/passengers/${passengerId}`, {
      method: 'PUT',
      body: { passenger },
    }),
  editGlobalServices: ({ orderId, services }) =>
    fetcher(`/orders/${orderId}/services`, {
      method: 'PUT',
      body: { services },
    }),
  editServices: ({ orderId, passengerId, services }) =>
    fetcher(`/orders/${orderId}/passengers/${passengerId}`, {
      method: 'PUT',
      body: { services },
    }),
  updateOrder: ({
    orderId,
    email,
    phoneNumber,
    acceptNotifications: { whatsapp, sms },
  }) =>
    fetcher(`/orders/${orderId}`, {
      method: 'PUT',
      body: {
        order: {
          client_email: email,
          client_phone: phoneNumber,
          accept_notifications: { whatsapp, sms },
        },
      },
    }),
  payOrder: ({ orderId, token, coupon, method }) => {
    const { protocol, hostname, port } = window.location
    const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`

    const source = {
      type: method,
    }

    if (token) {
      source.token = token
    }

    if (coupon) {
      source.coupon = coupon
    }

    const body = {
      payment: {
        params: {
          source,
          success_url: `${baseUrl}/checkout/${orderId}/success`,
          failure_url: `${baseUrl}/checkout/${orderId}/payment`,
        },
        provider: 'checkout',
      },
    }

    return fetcher(`/orders/${orderId}/invoice/payments`, {
      method: 'POST',
      body,
    })
  },
})

export default checkout
