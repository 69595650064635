import { useRouter } from 'next/router'
import { useContext, useState } from 'react'
import clsx from 'clsx'
import Link from 'next/link'

import styles from './Footer.module.css'
import { WHATSAPP_URL } from './constants'

import api from '@/services/api'

// Icons
import Twitter from '@/assets/svg/twitter.svg'
import Linkedin from '@/assets/svg/linkedin.svg'
import Facebook from '@/assets/svg/facebook.svg'
import Instagram from '@/assets/svg/instagram.svg'
import IataLogo from '@/assets/svg/iata-logo.svg'

// Components
import TrustpilotScore from '@/components/TrustpilotScore'
import Button from '@/components/ui/button'
import Trans from '@/components/i18n/Trans'

// Hooks
import useTranslation from '@/hooks/translation'
import useCookie from '@/hooks/useCookie'
import useUser from '@/hooks/user'

// context
import { LastNewsContext } from '@/providers/last-news'

function FooterComponent({ sticky = true }) {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const { showCookieBanner } = useCookie()
  const { isFlightClub } = useUser()
  const [subscribedToNewsletter, setSubscribedToNewsletter] = useState(false)
  const lastNews = useContext(LastNewsContext)

  const subscribeToNewsletter = async (event) => {
    event.preventDefault()

    try {
      await api.newsletter.subscribe(event.target.email.value)
      event.target.email.value = ''
      setSubscribedToNewsletter(true)
    } catch (e) {
      console.error(e)
    }
  }

  const showNews = locale === 'fr' && lastNews?.length > 0
  const footerColumnsSize = showNews ? 'col-span-4 md:col-span-2' : 'col-span-4'

  return (
    <footer
      className={clsx('pt-40 md:pt-80 pb-40 bg-white bottom-0 w-full z-0', {
        'md:sticky': sticky,
      })}
    >
      <div className="container">
        <ul className="flex items-center mb-30 sm:mb-70">
          <li className="w-40 h-40 mr-10">
            <a
              href="https://www.facebook.com/ulyssetravel/"
              target="_blank"
              rel="noreferrer noopener"
              title="Facebook Ulysse"
              aria-label="Facebook Ulysse"
              className="flex items-center justify-center w-full h-full rounded-full border-2 border-dark text-dark hover:text-white hover:bg-dark motion-safe:transition motion-safe:duration-150 ease-out"
            >
              <Facebook />
            </a>
          </li>
          <li className="w-40 h-40 mr-10">
            <a
              href="https://twitter.com/ulyssetravel"
              target="_blank"
              rel="noreferrer noopener"
              title="Twitter Ulysse"
              aria-label="Twitter Ulysse"
              className="flex items-center justify-center w-full h-full rounded-full border-2 border-dark text-dark hover:text-white hover:bg-dark motion-safe:transition motion-safe:duration-150 ease-out"
            >
              <Twitter />
            </a>
          </li>
          <li className="w-40 h-40 mr-10">
            <a
              href="https://www.linkedin.com/company/ulysse-travel/"
              target="_blank"
              rel="noreferrer noopener"
              title="LinkedIn Ulysse"
              aria-label="LinkedIn Ulysse"
              className="flex items-center justify-center w-full h-full rounded-full border-2 border-dark text-dark hover:text-white hover:bg-dark motion-safe:transition motion-safe:duration-150 ease-out"
            >
              <Linkedin />
            </a>
          </li>
          <li className="w-40 h-40 mr-10">
            <a
              href="https://www.instagram.com/ulysse.travel/"
              target="_blank"
              rel="noreferrer noopener"
              title="Instagram Ulysse"
              aria-label="Instagram Ulysse"
              className="flex items-center justify-center w-full h-full rounded-full border-2 border-dark text-dark hover:text-white hover:bg-dark motion-safe:transition motion-safe:duration-150 ease-out"
            >
              <Instagram />
            </a>
          </li>
        </ul>
        <div
          className={clsx('flex flex-col mb-30 gap-30 justify-between', {
            'md:flex-row': !showNews,
          })}
        >
          <div className="basis-5/12 xl:basis-1/2">
            <p className="text-35 font-bold leading-negative sm:leading-none mb-20 sm:mb-30">
              {t('components.footer.title')}
            </p>
            <p className="text-18 sm:text-22 leading-snug opacity-60">
              {t('components.footer.subtitle')}
            </p>
          </div>
          <div className="flex flex-col sm:grid grid-cols-12 gap-30 gap-y-10 justify-between">
            <div
              className={clsx('flex flex-col items-start', footerColumnsSize)}
            >
              <p className="text-18 font-bold mb-20 md:mt-0">
                {t('components.footer.superPowers')}
              </p>
              <Link href="/gift" className={styles.link}>
                {t('components.footer.giftCard')}
              </Link>
              <Link href="/corporate-gift" className={styles.link}>
                {t('components.footer.corporateGiftCard')}
              </Link>
              {locale === 'fr' ? (
                <>
                  <Link href="/ancv" className={styles.link}>
                    {t('components.footer.ancv')}
                  </Link>
                  <Link href="/cars" className={styles.link}>
                    Louer une voiture
                  </Link>
                </>
              ) : null}
              {isFlightClub ? (
                WHATSAPP_URL !== null ? (
                  <Link
                    href={
                      WHATSAPP_URL +
                      '?text=' +
                      encodeURI(t('prioritySupport.defaultText'))
                    }
                    target="_blank"
                    className={styles.link}
                  >
                    <Trans
                      i18nKey="components.footer.prioritySupport"
                      components={{
                        b: <p className="font-bold" />,
                      }}
                    />
                  </Link>
                ) : null
              ) : (
                <Link href="/flight-club" className={styles.link}>
                  <Trans
                    i18nKey="components.footer.flightClub"
                    components={{
                      b: <p className="font-bold" />,
                    }}
                  />
                </Link>
              )}
            </div>
            <div
              className={clsx('flex flex-col items-start', footerColumnsSize)}
            >
              <p className="text-18 font-bold mb-20 md:mt-0">
                {t('components.footer.knowMore')}
              </p>
              {locale === 'fr' && (
                <a
                  href="https://ulysse.com/news"
                  className={styles.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('components.footer.travelNews')}
                </a>
              )}
              <a
                href="https://ulyssetravel.notion.site/Roadmap-publique-bbc96156d0e44f3fb675dd639fad3fff"
                target="_blank"
                rel="noreferrer noopener"
                title="roadmap Ulysse"
                className={styles.link}
              >
                {t('components.footer.roadmap')}
              </a>
              {/*<a
                href="https://community.ulysse.com/"
                target="_blank"
                rel="noreferrer noopener"
                title="roadmap Ulysse"
                className={styles.link}
              >
                {t('components.footer.community')}
              </a>*/}
              <a href="mailto:support@ulysse.com" className={styles.link}>
                {t('components.footer.contactUs')}
              </a>
              {locale === 'fr' && (
                <Link href="/guides" className={styles.link}>
                  {"Les guides d'Ulysse"}
                </Link>
              )}
              <a
                href={t('urls.help')}
                target="_blank"
                rel="noreferrer noopener"
                className={styles.link}
              >
                {t('components.footer.help')}
              </a>
              {/*<a
                href="https://blog.ulysse.com/"
                target="_blank"
                rel="noreferrer noopener"
                className={styles.link}
              >
                {t('components.footer.blog')}
              </a>*/}
            </div>
            <div
              className={clsx('flex flex-col items-start', footerColumnsSize)}
            >
              <p className="text-18 font-bold mb-20 md:mt-0">
                {t('components.footer.misc')}
              </p>
              <Link
                href="/terms-and-conditions"
                className={styles.link}
                target="_blank"
              >
                {t('components.footer.conditions')}
              </Link>
              <Link href="/legals" className={styles.link} target="_blank">
                {t('components.footer.legals')}
              </Link>
              <Link
                href={
                  locale === 'fr'
                    ? '/pdf/ulysse-privacy-policy-fr.pdf'
                    : '/pdf/ulysse-privacy-policy-en.pdf'
                }
                className={styles.link}
                target="_blank"
                locale={false}
              >
                {t('components.footer.privacyPolicy')}
              </Link>
              <a
                href="https://www.welcometothejungle.com/fr/companies/ulysse/jobs"
                target="_blank"
                rel="noreferrer noopener"
                className={styles.link}
              >
                {t('components.footer.jobs')}
              </a>
              <Button
                className={`${styles.link} text-left`}
                unstyled
                onClick={() => showCookieBanner()}
              >
                {t('components.footer.cookies')}
              </Button>
            </div>
            {showNews && (
              <div
                className={clsx(
                  'grid grid-flow-row auto-rows-max items-start col-span-12 md:col-span-6'
                )}
              >
                <p className="text-18 font-bold mb-20 md:mt-0">
                  Nos dernières news
                </p>
                {lastNews.map((news, index) => (
                  <a
                    key={`last-new-${index}`}
                    href={news.link}
                    target="_blank"
                    rel="noreferrer noopener"
                    className={clsx(styles.link, 'truncate')}
                  >
                    {news.title}
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-30 items-start md:items-end xl:items-start justify-between mb-30">
          <div className="basis-5/12 xl:basis-1/2 flex flex-row gap-30 items-center justify-start">
            <div>
              <TrustpilotScore layout="block" />
            </div>
            <div className="flex text-dark-40">
              <IataLogo />
            </div>
          </div>
          {/* newsletter */}
          <div className="grow flex flex-col gap-20">
            <div>
              <p className="text-18 font-bold">
                {t('components.footer.newsletter.intro')}
              </p>
              <p className="text-18 opacity-60">
                {t('components.footer.newsletter.subintro')}
              </p>
            </div>
            {!subscribedToNewsletter ? (
              <form
                onSubmit={subscribeToNewsletter}
                className="flex rounded-md bg-gray-light"
              >
                <input
                  type="email"
                  name="email"
                  id="email_input"
                  className="grow rounded-md bg-transparent py-16 px-20 text-14 md:text-18 focus:outline-none focus:ring-gray-20 focus:ring-1"
                  placeholder={t('components.footer.newsletter.label')}
                />
                <button
                  type="submit"
                  className="inline-flex items-center rounded-md px-20 py-2 text-sm font-bold text-gray-700 hover:bg-gray-20 border-none focus:ring-1 focus:ring-gray-20 transition duration-300 ease-out"
                >
                  {t('components.footer.newsletter.cta')}
                </button>
              </form>
            ) : (
              <p className="mt-5 text-sm text-green">
                {t('components.footer.newsletter.success')}
              </p>
            )}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterComponent
