import { Component } from 'react'
import ErrorLayout from '@/layouts/error'
import { reportError } from '@/utils/error'
import withTranslation from 'next-translate/withTranslation'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error) {
    reportError(error)
  }

  render() {
    if (this.state.hasError) {
      const { t } = this.props.i18n
      return (
        <ErrorLayout
          title={t('components.errorBoundary.title')}
          subtitle={t('components.errorBoundary.subtitle')}
          message={t('components.errorBoundary.message')}
          buttonText={t('components.errorBoundary.buttonText')}
          buttonHref="/"
        />
      )
    }

    return this.props.children
  }
}

export default withTranslation(ErrorBoundary, 'common')
