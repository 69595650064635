function executeHit(fetcher, slug) {
  return fetcher(`/partnerships/${encodeURI(slug)}`, {
    method: 'GET',
    api: process.env.NEXT_PUBLIC_HOMER_API,
  })
}

const partnerships = (fetcher) => ({
  get: (slug) => executeHit(fetcher, slug),
  getWithoutFailing: (slug) =>
    executeHit(fetcher, slug).catch((err) => {
      console.warn(`Error while fetching partner ${slug}`, err)
      return { data: null }
    }),
})

export default partnerships
