import { cloneElement, useRef } from 'react'
import { useOverlayTriggerState } from '@react-stately/overlays'
import { Transition } from 'react-transition-group'
import { useIsSSR } from '@react-aria/ssr'
import { mergeProps } from '@react-aria/utils'
import { Popover } from '.'
import clsx from 'clsx'

function isChildOf(parent, child) {
  let element = child
  while (element != null) {
    if (element === parent) {
      return true
    }
    element = element.parentElement
  }

  return false
}

export default function DropDown(props) {
  const isSSR = useIsSSR()
  const state = useOverlayTriggerState({})

  const overlayRef = useRef()
  const localTriggerRef = useRef()
  const triggerRef = props.triggerRef || localTriggerRef
  const timeoutRef = useRef()

  const clearAndSetTimeout = (func, timeout = 0) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef)
      timeoutRef.current = null
    }
    if (timeout <= 0) {
      func()
    } else {
      timeoutRef.current = setTimeout(func, timeout)
    }
  }

  const trigger =
    typeof props?.trigger === 'function'
      ? props.trigger({ ...state })
      : props?.trigger

  return (
    <div className="relative z-20">
      <div
        onMouseEnter={() => clearAndSetTimeout(state.open, props.delay ?? 200)}
        onMouseLeave={() => clearAndSetTimeout(state.close, 10)}
      >
        {trigger
          ? cloneElement(
              trigger,
              mergeProps(props.trigger.props, {
                ref: triggerRef,
              })
            )
          : null}
        {!isSSR ? (
          <Transition
            nodeRef={overlayRef}
            in={state.isOpen}
            timeout={props.timeout || 0}
            mountOnEnter={true}
            unmountOnExit={true}
          >
            {(transitionState) => {
              return (
                <div className={clsx(props.overlayClassName)}>
                  <Popover
                    {...props}
                    ref={overlayRef}
                    contentProps={{ className: 'absolute right-0' }}
                    state={state}
                    transitionState={transitionState}
                    shouldCloseOnInteractOutside={(e) =>
                      !isChildOf(triggerRef.current, e)
                    }
                    onClose={() => state.close()}
                  />
                </div>
              )
            }}
          </Transition>
        ) : null}
      </div>
    </div>
  )
}
