import { createContext, useEffect } from 'react'
import Plausible from 'plausible-tracker'
import * as Sentry from '@sentry/nextjs'
import { useRouter } from 'next/router'
import { v4 as uuid } from 'uuid'
import useUser from '@/hooks/user'
import { initAffilae } from '@/utils/affilae'
import { initRudderStack } from '@/utils/rudderstack'
import { useCallback } from 'react'
import { useContext } from 'react'
import { ExperimentsContext } from './experiments'
import { useMemo } from 'react'

export const AnalyticsContext = createContext()
export const AnalyticsPropertyContext = createContext({})

/**
 * Init analytics tools
 */

if (typeof window !== 'undefined') {
  initRudderStack()
  initAffilae()
}

const rudder = () =>
  (typeof window !== 'undefined' && window.rudderanalytics) || undefined

const plausible = Plausible({
  // trackLocalhost: true,
  domain:
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
      ? 'ulysse.com'
      : 'odyssey-staging.ulysse.com',
})

let elyticsWarnings = 0
const elytics = (eventName, props) => {
  if (typeof window === 'undefined') {
    console.warn('[Elytics] ignore event, server side call', eventName, props)
    return
  }

  if (process.env.NEXT_PUBLIC_ELYTICS_URL) {
    fetch(process.env.NEXT_PUBLIC_ELYTICS_URL + '/api/events', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        n: eventName,
        p: props,
        r: document.referrer || null,
        u: getEnhancedURL()?.url || window.location.href,
        d: location.hostname,
        w: window.innerWidth,
      }),
    }).catch((e) => {
      console.warn(
        '[Elytics] error while posting an event',
        eventName,
        props,
        e
      )
    })
  } else {
    if (elyticsWarnings++ === 0) {
      console.warn('[Elytics] ignoring event (deactivated)', eventName, props)
    } else {
      console.log('[Elytics] ignoring event (deactivated)', eventName, props)
    }
  }
}

/**
 * Safe track the given event.
 * Unlike the track method included inside the provider, this one will not add any context properties.
 * To be used if you know perfectly what you are doing!
 *
 * @param {string} eventName the event name
 * @param {Object} props the props alongside the event
 */
export function safeRawTrack(eventName, props) {
  // remove unsuitable props for safe tracking
  if (typeof props === 'object') {
    delete props['email']
    delete props['phone']
    delete props['order_id']
  }

  plausible.trackEvent(eventName, { props })
  elytics(eventName, props)
}

/**
 * Enhanced the query string if needed
 * @returns {string} null of nothing to enhanced, the new query string otherwise
 */
export function getEnhancedURL() {
  if (typeof window === 'undefined') {
    return null
  }

  if (window.location.search.length < 1) {
    return null
  }

  const params = new URLSearchParams(window.location.search.substring(1))

  if (params.has('partnerId') && !params.has('utm_source')) {
    const campaign = { source: params.get('partnerId'), medium: null }

    // otherwise it can be widget for example
    if (!params.has('utm_medium')) {
      if (params.has('emplacement')) {
        campaign.medium = params.get('emplacement')
      } else if (params.has('placement')) {
        campaign.medium = params.get('placement')
      } else {
        campaign.medium = 'partner'
      }
    }

    params.append('utm_source', campaign.source)
    if (campaign.medium) {
      params.append('utm_medium', campaign.medium)
    }

    return {
      campaign,
      search: '?' + params.toString(),
      url:
        window.location.origin +
        window.location.pathname +
        '?' +
        params.toString(),
    }
  }

  return null
}

/**
 * Called when the route did change
 */
const handleRouteChange = () => {
  const url = getEnhancedURL()
  if (url === null) {
    plausible.trackPageview()
    rudder()?.page()
  } else {
    plausible.trackPageview({ url: url.url })
    rudder()?.page(
      { url: url.url, search: url.search, tab_url: url.url },
      { campaign: url.campaign }
    )
  }
  elytics('pageview', {})
}

let connectedUserEmail = null
function trackUserIfNecessary(user) {
  if (connectedUserEmail !== user?.email) {
    connectedUserEmail = user?.email

    rudder()?.reset()
    Sentry.configureScope((scope) => scope.setUser(null))

    if (user?.email) {
      rudder()?.identify(user.email)
      Sentry.setUser({ email: user.email })
    }
  }
}

/**
 *
 * @param {Object} experiments
 * @param {Object} props
 * @returns
 */
function mergeProps(experiments, props = {}) {
  return experiments ? { experiments, ...props } : props
}

/**
 * Creates the analytics provider
 */
export function AnalyticsProvider({ children }) {
  const router = useRouter()
  const { user } = useUser()
  const { getExperiments } = useContext(ExperimentsContext)
  const experiments = useMemo(() => getExperiments(), [getExperiments])

  /**
   * Handle route and user change
   */

  // track the first page view
  useEffect(handleRouteChange, [])

  // liston for route change (if the router did changed)
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  // connect and track the user
  trackUserIfNecessary(user)

  /**
   * Declare tracking methods
   */

  const track = useCallback(
    (eventName, props) => {
      props = mergeProps(experiments, props)
      safeRawTrack(eventName, props)

      props['event_id'] = uuid()
      rudder()?.track(eventName, props)
    },
    [experiments]
  )

  const identify = useCallback(
    (props, id) => {
      rudder()?.identify(id ? id : user?.email ? user.email : '', props)
      elytics('identify', id ? id : user?.email ? user.email : '')
    },
    [user?.email]
  )

  const trackWithElytics = useCallback(
    (eventName, props) => {
      elytics(eventName, mergeProps(experiments, props))
    },
    [experiments]
  )

  return (
    <AnalyticsContext.Provider
      value={{
        track,
        identify,
        trackWithElytics,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

export function AnalyticsPropertyMerger({ properties, children }) {
  const props = properties ?? {}
  const ctxProps = useContext(AnalyticsPropertyContext)
  return (
    <AnalyticsPropertyContext.Provider value={{ ...ctxProps, ...props }}>
      {children}
    </AnalyticsPropertyContext.Provider>
  )
}
