class Service {
  type

  constructor(service) {
    Object.assign(this, service)
  }

  get isIncluded() {
    return this.total_amount === 0
  }

  get count() {
    return this.quantity
  }

  get sliceIds() {
    return this.slices_ids
  }

  isEqualQuantity(quantity) {
    return this.quantity === quantity
  }
}

export class LuggageService extends Service {
  get isChecked() {
    return this.meta_data.type === 'checked'
  }
}

export default Service
