/* eslint-disable @typescript-eslint/no-var-requires */
const hoistNonReactStatics = require('hoist-non-react-statics')
const { captureMessage } = require('@sentry/nextjs')

module.exports = {
  locales: ['fr', 'en', 'de', 'it', 'es', 'nl'],
  defaultLocale: 'fr',
  pages: {
    '*': ['common'],
  },
  staticsHoc: hoistNonReactStatics,
  logger: (missingKey) => {
    const message = `Missing translation key "${missingKey.i18nKey}" in namespace "${missingKey.namespace}"`

    if (process.env.NODE_ENV === 'production') {
      captureMessage(message)
    } else {
      console.warn(message)
    }
  },
}
