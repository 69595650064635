import clsx from 'clsx'

import { STORE_ANDROID_URL, STORE_IOS_URL } from '../constants'

import useAnalytics from '@/hooks/analytics'
import { useState, useRef, useEffect } from 'react'
import { useIsSSR } from '@react-aria/ssr'
import useMobileOperator from '@/hooks/mobile-operator'
import useBreakpoints from '@/hooks/breakpoints'
import useTranslation from '@/hooks/translation'
import { useLocalStorage } from '@/hooks/useLocalStorage'

import Image from 'next/legacy/image'
import { Transition } from 'react-transition-group'
import Cross from '@/assets/svg/cross.svg'
import MobileApp from '@/assets/img/downloadapp/mobile-app.png'

export default function DownloadAppBanner() {
  const { t } = useTranslation()
  const { isMobile } = useBreakpoints()
  const { track } = useAnalytics()
  const os = useMobileOperator()
  const bannerRef = useRef()
  const isSSR = useIsSSR()
  const { readItem: getBannerDismissed, setItem: setBannerDismissed } =
    useLocalStorage('@ulysse:downloadAppBannerDismissed')
  const [bannerVisible, setBannerVisible] = useState(false)

  useEffect(() => {
    if (!getBannerDismissed()?.value && (os == 'Android' || os == 'iOS')) {
      window.setTimeout(() => {
        track('website_download_mobileapp_displayed', {
          mobileOs: os,
        })
        setBannerVisible(true)
      }, 700)
    }
  }, [os, getBannerDismissed])

  if (
    !isSSR &&
    isMobile &&
    !getBannerDismissed()?.value &&
    (os == 'Android' || os == 'iOS')
  ) {
    return (
      <Transition
        nodeRef={bannerRef}
        in={bannerVisible}
        timeout={800}
        unmountOnExit={true}
      >
        {(state) => (
          <div
            ref={bannerRef}
            className={clsx('relative h-80', 'ease-in-out duration-700', {
              'opacity-1 max-h-70': state == 'entering' || state == 'entered',
              'opacity-0 max-h-0': state == 'exiting' || state == 'exited',
            })}
          >
            <div
              className={clsx(
                'fixed container backdrop-blur-[2px] bg-white-60 flex flex-row gap-15 justify-center items-center pl-0 pr-20 shadow-lg w-full',
                'ease-in-out duration-700',
                {
                  'opacity-1 max-h-70':
                    state == 'entering' || state == 'entered',
                  'opacity-0 max-h-0': state == 'exiting' || state == 'exited',
                }
              )}
            >
              <div
                className={clsx(
                  'w-70 flex self-end',
                  'ease-in-out duration-300',
                  {
                    'opacity-1 translate-x-0 delay-300':
                      state == 'entering' || state == 'entered',
                    'opacity-0 -translate-x-200 delay-200':
                      state == 'exiting' || state == 'exited',
                  }
                )}
              >
                <Image
                  src={MobileApp}
                  layout="intrinsic"
                  alt={t('components.downloadapp.banner.title')}
                />
              </div>
              <div className="grow py-15">
                <p className="text-18 leading-[23px] font-bold">
                  {t('components.downloadapp.banner.title')}
                </p>
                <a
                  href={os == 'iOS' ? STORE_IOS_URL : STORE_ANDROID_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    track('website_download_mobileapp_clicked', {
                      mobileOs: os,
                      where: 'app_banner',
                    })
                    setBannerVisible(false)
                  }}
                  className="text-14 leading-[18px] underline"
                >
                  {t('components.downloadapp.banner.link')}
                </a>
              </div>
              <div
                className={clsx(
                  'w-[37px] h-[37px] border rounded-full bg-white shadow-lg flex justify-center border-gray-light',
                  'ease-in-out duration-300',
                  {
                    'opacity-1 translate-x-0 delay-300':
                      state == 'entering' || state == 'entered',
                    'opacity-0 translate-x-200 delay-200':
                      state == 'exiting' || state == 'exited',
                  }
                )}
                onClick={() => {
                  track('website_download_mobileapp_dismissed', {
                    mobileOs: os,
                  })
                  setBannerDismissed({
                    version: 1,
                    date: Date.now(),
                    value: true,
                  })
                  setBannerVisible(false)
                }}
              >
                <Cross width="11" />
              </div>
            </div>
          </div>
        )}
      </Transition>
    )
  }

  return null
}
