export const getCookie = (name) => {
  if (typeof document === 'undefined') return
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
  return v ? v[2] : null
}

export const setCookie = (name, value, days) => {
  if (typeof document === 'undefined') return
  let expiresIn

  if (days) {
    const d = new Date()
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
    expiresIn = d.toGMTString()
  }

  document.cookie = name + '=' + value + ';path=/;expires=' + expiresIn
}

export const delCookie = (name) => {
  if (typeof document === 'undefined') return
  document.cookie = name + '=' + ';path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT'
}

export function setVersionnedCookie(name, version, value, days, key = 'value') {
  setCookie(name, JSON.stringify({ __version__: version, [key]: value }), days)
}

export function getVersionnedCookie(name, key = 'value') {
  const value = getCookie(name)
  if (value) {
    const json = JSON.parse(value)
    if (json) {
      return [json?.['__version__'] || 0, json?.[key]]
    }
  }
  return [-1, null]
}

// -------------------------------------------------->

export const EXPERIMENTS_COOKIE = 'ulysse:experiments'
export const PARTNERSHIPS_COOKIE = 'ulysse:partnerships'
export const GIFTCARDS_PROMO_COOKIE = 'ulysse:gcp' // gift card promos

export function getExperimentsCookie() {
  return getVersionnedCookie(EXPERIMENTS_COOKIE, 'experiments')
}

export function setExperimentsCookie(version, value) {
  setVersionnedCookie(EXPERIMENTS_COOKIE, version, value, 7, 'experiments')
}

export function getPartnershipsCookie() {
  return parsePartnershipsCookie(getCookie(PARTNERSHIPS_COOKIE))
}

export function parsePartnershipsCookie(value) {
  if (!value) {
    return null
  }
  const json = JSON.parse(value) || []
  const sortedPartners = json?.sort((e) => e.visited_at).reverse() || []
  return sortedPartners?.length > 0 ? sortedPartners[0] : null
}

export function setPartnershipsCookie(partnerId) {
  setCookie(
    PARTNERSHIPS_COOKIE,
    JSON.stringify([
      {
        name: partnerId,
        visited_at: Math.floor(new Date().getTime() / 1000),
      },
    ]),
    180
  )
}

export function delPartnershipsCookie() {
  delCookie(PARTNERSHIPS_COOKIE)
}

export function setGiftCardPromoCookie(values) {
  setCookie(GIFTCARDS_PROMO_COOKIE, JSON.stringify(values ?? []), 180)
}

export function getGiftCardPromoCookie() {
  return JSON.parse(getCookie(GIFTCARDS_PROMO_COOKIE) ?? null) ?? []
}
