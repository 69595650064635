// Components
import Button from '@/components/ui/button'

// Hooks
import useTranslation from '@/hooks/translation'
import useCookie from '@/hooks/useCookie'

export default function CookieBanner() {
  const { t } = useTranslation()
  const { isCookieBannerVisible, acceptCookie, refuseCookie } = useCookie()

  return isCookieBannerVisible ? (
    <section className="md:flex z-20 fixed md:left-auto left-15 bottom-15 md:bottom-30 right-15 md:right-20 md:w-8/12 md:max-w-[1000px] p-15 bg-white/[.95] shadow rounded border border-dark-10">
      <p className="text-dark-80 text-14 mb-15 md:mb-0 md:mr-10">
        {t('components.cookieConsent.text')}
      </p>
      <div className="shrink-0 flex items-center">
        <Button
          color="dark"
          size="sm"
          onClick={acceptCookie}
          className="shrink-0"
        >
          {t('components.cookieConsent.accept')}
        </Button>
        <Button
          plain
          color="dark"
          size="sm"
          className="ml-15 shrink-0"
          onClick={refuseCookie}
        >
          {t('components.cookieConsent.decline')}
        </Button>
      </div>
    </section>
  ) : null
}
