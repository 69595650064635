import Link from 'next/link'
import ArrowRight from '@/assets/img/conciergerie/arrow-right.svg'
import styles from './CarBanner.module.css'
import clsx from 'clsx'
import useAnalytics from '@/hooks/analytics'

const CARS_PATH = '/cars'

export default function CarBanner({ children, path = '/' }) {
  const { track } = useAnalytics()

  return (
    <Link
      href={CARS_PATH}
      passHref
      onClick={() => track('cars_banner_clicked')}
    >
      <div className="container mx-auto flex pt-20">
        <section
          className={clsx(
            'flex mx-auto z-10 pl-[47px] pr-15 py-5 text-white items-center',
            path === '/' && styles.banner,
            path !== '/' && styles.bannerGift
          )}
        >
          <div className="relative w-[1px] h-[28px]">
            <div
              className="absolute top-[50%] -left-[40px] -translate-y-[60%] text-[25px] -rotate-[22deg] -scale-x-[1] box-shadow-lg"
              style={{
                textShadow: '4px 8px 5px rgba(0, 0, 0, 0.25)',
              }}
            >
              🚗
            </div>
          </div>
          <div className="flex-grow py-5 text-center text-12 md:text-14 font-bold">
            {children}
          </div>
          {path != CARS_PATH && (
            <>
              <div className="py-5 px-10">
                <div className="h-[17px] w-[2px] bg-white/20 border-l border-noir/20 border-l-1"></div>
              </div>
              <div className="py-5">
                <ArrowRight />
              </div>
            </>
          )}
        </section>
      </div>
    </Link>
  )
}
