const b2b = (fetcher) => ({
  postContactRequest: ({ company, name, email, tel, budget, locale }) =>
    fetcher('/contact/b2b', {
      method: 'POST',
      body: {
        type: 'contact',
        email,
        fields: {
          company,
          name,
          tel,
          budget,
          locale,
        },
      },
    }),

  postSpecificRequest: ({ email, locale }) =>
    fetcher('/contact/b2b', {
      method: 'POST',
      body: { type: 'specific_request', email, fields: { locale } },
    }),
})

export default b2b
