import { Place } from '../location/Place'
import { Fetcher, HomerFetcher, HomerResponse } from './fetcher'

/**
 * An ad request
 */
export type BestSearchRequest = {
  origin: string
  destination: string

  maxPrice?: number
  duration?: {
    min: number
    max?: number
  }
}

/**
 * Best search object response
 */
export type BestSearch = {
  origin: string
  destination: string
  departure_date: string
  return_date: string
  cabin_class: string
}

/**
 * Days response
 */
export type Days = Array<{
  date: string
  available_offers: number
  price_score: number
}>

/**
 * A cheapest date request
 */
export type CheapestDateRequest = {
  origin?: string
  destination?: string
  limit?: number
}

/**
 * Cheapest response
 */
export type BestOffer = {
  origin: Place
  destination: Place
  price: number
  departure_date: string
  return_date: string
  airline: {
    code: string
    name: string
  }
}

export type CheapestDates = Array<BestOffer>

// ------------------------------------------->

const calendar = (fetcher: Fetcher<unknown>) => ({
  getDays: (
    origin: string,
    destination: string,
    type: string,
    start: string,
    end: string,
    partnership?: string,
    departureDate?: string
  ): Promise<HomerResponse<Days>> => {
    const query: Record<string, string> = {
      origin,
      destination,
      journey_type: type,
      start,
      end,
    }

    if (partnership) {
      query.partnership = partnership
    }
    if (departureDate) {
      query.departure_date = departureDate
    }

    return (fetcher as HomerFetcher<Days>)(
      '/calendar?' + new URLSearchParams(query)
    )
  },

  getBestSearchParameters: (
    req: BestSearchRequest
  ): Promise<HomerResponse<BestSearch>> => {
    const query: Record<string, string> = {
      origin: req.origin,
      destination: req.destination,
    }

    if (req.maxPrice) {
      query.max_price = req.maxPrice.toString()
    }

    if (req.duration?.min) {
      query.min_duration_in_days = req.duration.min.toString()
    }

    if (req.duration?.max) {
      query.max_duration_in_days = req.duration.max.toString()
    }

    return (fetcher as HomerFetcher<BestSearch>)(
      '/calendar/best_search_parameters?' + new URLSearchParams(query)
    )
  },

  getCheapestDates: (
    request: CheapestDateRequest
  ): Promise<HomerResponse<CheapestDates>> => {
    const query: Record<string, string> = {}

    if (request.origin) {
      query.origin = request.origin
    }
    if (request.destination) {
      query.destination = request.destination
    }
    if (request.limit) {
      query.limit = request.limit.toString()
    }

    return (fetcher as HomerFetcher<CheapestDates>)(
      '/calendar/cheapest_dates?' + new URLSearchParams(query)
    )
  },
})

export default calendar
