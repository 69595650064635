export function initAffilae() {
  // eslint-disable-next-line no-unused-vars
  window._ae = {
    pid: '5c0a78e23c04be2d8966dfc5',
  }
  ;(function () {
    var element = document.createElement('script')
    element.type = 'text/javascript'
    element.async = true
    element.src = '//static.affilae.com/ae-v3.5.js'
    var scr = document.getElementsByTagName('script')[0]
    scr.parentNode.insertBefore(element, scr)
  })()
}

export function trackConversion({ ruleKey, orderId, amount, currency }) {
  var aeEvent = {}
  /* KEY must be updated for each rule */
  aeEvent.key = ruleKey
  aeEvent.Conversion = {}
  /* Values below must be updated */
  aeEvent.Conversion.id = orderId
  aeEvent.Conversion.amount = amount
  aeEvent.Conversion.payment = 'online'
  aeEvent.Conversion.voucher = ''
  aeEvent.Conversion.currency = currency

  if (typeof window !== 'undefined' && 'AeTracker' in window) {
    window.AeTracker.sendConversion(aeEvent)
  } else {
    ;(window.AE = window.AE || []).push(aeEvent)
  }
}
